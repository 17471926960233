import React from "react";

//img
import ruche from "../../assets/img/icon/ruche.png";
import suivis from "../../assets/img/icon/suivis.png";
import bilan from "../../assets/img/icon/bilan.png";
import bio from "../../assets/img/icon/bio.png";
import com from "../../assets/img/icon/com.png";
import miel from "../../assets/img/icon/miel.png";
import visible from "../../assets/img/icon/visible.png";
import img from "../../assets/img/autre/particulier.png";

import lavande from "../../assets/img/icon/lavande.png";
import tournesol from "../../assets/img/icon/tournesol.png";
import bleuet from "../../assets/img/icon/bleuet.png";
import cosmos from "../../assets/img/icon/cosmos.png";

//bibliothèques
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import ReactFullpage from "@fullpage/react-fullpage";
import { Col, Row, Container, Button } from "react-bootstrap";
import Footer from "../Footer/Footer";

//Carousel
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
SwiperCore.use([Pagination, Navigation, Autoplay]);

// Utilisation fullPage
class Particulier extends React.Component {
  onLeave(origin, destination, direction) {
    console.log("Leaving section " + origin.index);
  }
  afterLoad(origin, destination, direction) {
    console.log("After load: " + destination.index);
  }
  render() {
    return (
      <ReactFullpage
        responsiveWidth={1000}
        navigation={true}
        onLeave={this.onLeave.bind(this)}
        afterLoad={this.afterLoad.bind(this)}
        render={({ state, fullpageApi }) => {
          return (
            <div id="fullpage-wrapper">
              {/* Section 1*/}
              <div className="section">
                <Container fluid>
                  <Row>
                    <Col lg="6" md="12" className="sm-mb-10">
                      <div className="loc-img3"></div>
                    </Col>

                    <Col
                      lg="4"
                      md="12"
                      className="align-self-center mx-auto my-auto sm-plr-10"
                    >
                      <h1 className="font-italic text-uppercase font-20px text-red">
                        Parrainez une ruche et avant tout<br></br>
                        <span className="font-26px font-bold font-normal text-uppercase">
                          une démarche de biodiversité
                        </span>
                      </h1>
                      <br></br>
                      <div>
                        <p className="font-14px ">
                          Vous avez vu une <strong>ruche Apiago</strong>{" "}
                          installée dans un lieu accueillant du public ? Cette
                          ruche est l’étendard d’une{" "}
                          <strong>
                            démarche engagée en faveur de la préservation des
                            pollinisateurs
                          </strong>
                          . Vous pouvez également{" "}
                          <strong>contribuer à cette démarche</strong> en la
                          parrainant. En récompense de votre parrainage, recevez
                          des pots d’un miel produit par des apiculteurs
                          indépendants
                        </p>
                        <br></br>
                        <img src={img} className="img-responsive" />
                        <br></br>
                        <br></br>
                        <Button
                          className="btn btn-dark rounded-pill btn-block"
                          href="http://connect.apiago.fr"
                          target="_blank"
                        >
                          Se connecter ou créer un compte
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* Section 2*/}
              <div className="section">
                <Container fluid>
                  <Row>
                    <Col
                      lg={{ span: 6, order: 1 }}
                      md={{ span: 12, order: 2 }}
                      className="align-self-center"
                    >
                      <Swiper
                        navigation={true}
                        pagination={true}
                        className="mySwiper"
                        autoplay={{
                          delay: 3000,
                          disableOnInteraction: true,
                        }}
                      >
                        <SwiperSlide className="text-center mx-auto my-auto">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={visible} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              UNE RUCHE VISIBLE
                            </h3>
                            <br></br>
                            <p className="font-14px sm-plr-10">
                              Une entreprise ou collectivité installe une{" "}
                              <strong>ruche de biodiversité Apiago® </strong>à
                              un emplacement visible du public,{" "}
                              <strong>
                                étendard d’une démarche engagée en faveur de la
                                biodiversité.
                              </strong>
                              <br></br>
                              <br></br>
                              <strong>
                                Vous pouvez également contribuer à cette
                                démarche{" "}
                              </strong>
                              en la parrainant et recevoir, en échange, des pots
                              de miel produits par des apiculteurs indépendants.
                            </p>
                          </Col>
                        </SwiperSlide>
                        <SwiperSlide className="text-center mx-auto my-auto">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={ruche} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              parrainage De la biodiversité
                            </h3>
                            <br></br>
                            <p className="font-14px sm-plr-10">
                              Rendez vous sur le site{" "}
                              <a
                                href="http://connect.apiago.fr"
                                target="_blank"
                                style={{ color: "#82293b" }}
                              >
                                connect.apiago.fr{" "}
                              </a>
                              pour créer gratuitement votre compte et accéder à
                              votre espace personnel.
                              <br></br>
                              <br></br>{" "}
                              <strong>
                                Choisissez la ruche Apiago dont vous souhaitez
                                parrainer la démarche associée et optez pour
                                l’une des formules de parrainage proposées.
                              </strong>
                              <br></br>
                              <br></br>
                              Une partie des fonds récoltés est utilisée pour
                              l’achat et la mise en œuvre de dispositifs
                              écologiques dans le but de recréer des espaces
                              plus naturels, propices au retour et à la
                              conservation de la biodiversité.
                            </p>
                          </Col>
                        </SwiperSlide>
                        <SwiperSlide className="text-center mx-auto my-auto">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={suivis} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              un suivi quotidien
                            </h3>
                            <br></br>
                            <p className="font-14px sm-plr-10">
                              Nous effectuons un{" "}
                              <strong>suivi quotidien et à distance </strong> de
                              la santé de l’essaim. La connectivité (GSM) de la
                              ruche permet de ne pas déranger les abeilles.
                              <br></br>
                              <br></br>
                              Nous assurons des
                              <strong> abeilles en bonne santé </strong> toute
                              l’année et intervenons sur place dès que cela est
                              nécessaire.
                            </p>
                          </Col>
                        </SwiperSlide>
                        <SwiperSlide className="text-center mx-auto my-auto">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={com} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              de l'information et du lien
                            </h3>
                            <br></br>
                            <p className="font-14px sm-plr-10">
                              Nous vous tenons informé des{" "}
                              <strong>
                                démarches engagées en faveur de la biodiversité.
                              </strong>
                              <br></br>
                              <br></br>
                              Chaque année au printemps, nous organisons sur le
                              lieu d’installation de la ruche une{" "}
                              <strong>
                                journée d’animations et découvertes sur le thème
                                de la biodiversité.
                              </strong>
                              <br></br>
                              <br></br>En tant que parrain ou marraine d’actions
                              en faveur de la biodiversité, vous pouvez{" "}
                              <strong>
                                venir à la rencontre de l’entreprise{" "}
                              </strong>
                              ou de la collectivité concernée.
                            </p>
                          </Col>
                        </SwiperSlide>
                        <SwiperSlide className="text-center mx-auto my-auto">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={miel} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              du miel en contrepartie
                            </h3>
                            <br></br>
                            <p className="font-14px sm-plr-10">
                              Grâce à votre parrainage,{" "}
                              <strong>
                                nous agissons directement afin de recréer des
                                espaces plus naturels
                              </strong>
                              , propices au retour et à la conservation de la
                              biodiversité. Cela signifie également
                              <strong>
                                {" "}
                                plus de sources de nourriture pour les abeilles
                                mellifères.
                              </strong>
                              <br></br>
                              <br></br>Vous en bénéficiez en recevant des pots
                              de miel produits par des apiculteurs indépendants.
                            </p>
                          </Col>
                        </SwiperSlide>
                        <SwiperSlide className="text-center mx-auto my-auto">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={bio} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              votre engagement
                            </h3>
                            <br></br>
                            <p className="font-14px sm-plr-10">
                              Vous agissez en faveur de la biodiversité autour
                              d’un <strong>projet mobilisateur.</strong> Les
                              fonds récoltés grâce aux parrainages viennent
                              s’ajouter à vos efforts entrepris dans le cadre de
                              cette démarche globale. Ils seront utilisés pour
                              l’achat et la mise en œuvre de semences, plants et
                              autres dispositifs écologiques.
                              <br></br>
                              <br></br>
                            </p>
                            <p className="section1 font-14px text-carousel">
                              Se contenter d’installer des ruches de production
                              de miel pour sauver les abeilles n’a pas de sens.
                              <br></br>
                              <br></br>
                              <strong>
                                Installer une ruche de biodiversité et recréer
                                des espaces naturels démontre une véritable
                                connaissance des enjeux actuels. Faites-le
                                savoir grâce à Apiago !
                              </strong>
                            </p>
                            <br></br>
                          </Col>
                        </SwiperSlide>
                      </Swiper>
                    </Col>

                    <Col lg={{ span: 6, order: 2 }} md={{ span: 12, order: 1 }}>
                      <div className="loc-img4"></div>
                    </Col>
                  </Row>
                </Container>
              </div>

              <div className="section">
                <Container>
                  <Row>
                    <Col
                      lg="6"
                      md="12"
                      className="align-self-center sm-text-center mt-10"
                    >
                      <Row className=" mx-auto my-auto">
                        <Col md="4">
                          <img src={cosmos} />
                        </Col>
                        <Col md="8">
                          <h1 className="text-red font-20px font-bold">
                            Membre Cosmos
                          </h1>
                          <p>✓ 3 pots de miel / an</p>
                          <p>✓ Subventions de projets</p>
                          <h4 className="font-18px">4,50€/ mois</h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      lg="6"
                      md="12"
                      className="align-self-center sm-text-center mt-10"
                    >
                      <Row className="mx-auto my-auto">
                        <Col md="4">
                          <img src={bleuet} />
                        </Col>
                        <Col md="8">
                          <h1 className="text-red font-20px font-bold">
                            Membre Bleuet
                          </h1>
                          <br></br>
                          <p>✓ 6 pots de miel / an</p>
                          <p>✓ Subventions de projets</p>
                          <br></br>
                          <h4 className="font-18px">8€/ mois</h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <br></br> <br></br> <br></br>
                  <Row>
                    <Col
                      lg="6"
                      md="12"
                      className="align-self-center sm-text-center mt-10"
                    >
                      <Row className="mx-auto my-auto">
                        <Col md="4">
                          <img src={lavande} />
                        </Col>
                        <Col md="8">
                          <h1 className="text-red font-20px font-bold">
                            Membre Lavande
                          </h1>
                          <br></br>
                          <p>✓ 12 pots de miel / an</p>
                          <p>✓ Subventions de projets</p>
                          <br></br>
                          <h4 className="font-18px">18€ / mois</h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      lg="6"
                      md="12"
                      className="align-self-center sm-text-center mt-10"
                    >
                      <Row className="mx-auto my-auto">
                        <Col md="4">
                          <img src={tournesol} />
                        </Col>
                        <Col md="8">
                          <h1 className="text-red font-20px font-bold">
                            Membre tournesol
                          </h1>
                          <br></br>
                          <p>✓ 24 pots de miel / an</p>
                          <p>✓ Subventions de projets</p>
                          <p>✓ Une mini ruche de biodiversité</p>
                          <br></br>
                          <h4 className="font-18px"> 36€/ mois</h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>

              <div className="section fp-auto-height">
                <Footer />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

export default Particulier;
