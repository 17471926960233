//base
import React from "react";

import { Link } from "react-router-dom";

//icones
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faShoppingBag, faUser } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/img/logo/logo.png";

class Menu extends React.Component {
  state = {
    isOpen: false,
  };

  toggleNav = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { isOpen } = this.state;
    return (
      <>
        <header id="header">
          <div className="bg-red">
            <button className="shop">
              <a href="http://connect.apiago.fr/">
                <FontAwesomeIcon icon={faUser} id="compte" />
              </a>
            </button>
            <button className="shop">
              <a href="http://www.apiago.fr/taberna/">
                <FontAwesomeIcon icon={faShoppingBag} id="shop" />
              </a>
            </button>
            <nav className="nav">
              <Link to="/">
                <img src={logo} className="logo" />
              </Link>
              <button className="toggle-menu" onClick={() => this.toggleNav()}>
                <div id="button" className={isOpen ? "open" : null}>
                  <span></span>
                </div>
              </button>
            </nav>
          </div>
        </header>

        <div id="menu" className={isOpen ? "open" : null}>
          <nav className="main-nav">
            <ul>
              <li>
                <Link to="/" onClick={() => this.toggleNav()}>
                  Accueil
                </Link>
              </li>
              <li>
                <Link to="/ruche" onClick={() => this.toggleNav()}>
                  La ruche Apiago
                </Link>
              </li>
              <li>
                <Link
                  to="/entreprise-et-collectivite"
                  onClick={() => this.toggleNav()}
                >
                  Entreprises et Collectivités
                </Link>
              </li>
              <li>
                <Link
                  to="/location-et-parrainage"
                  onClick={() => this.toggleNav()}
                >
                  Parrainer la biodiversité
                </Link>
              </li>
              <li>
                <Link to="/cueilleur" onClick={() => this.toggleNav()}>
                  Cueilleurs d'essaim
                </Link>
              </li>
              <li>
                <Link to="/equipe" onClick={() => this.toggleNav()}>
                  L'équipe
                </Link>
              </li>
              <li>
                <a
                  href="https://apiago.fr/taberna"
                  onClick={() => this.toggleNav()}
                >
                  Magasin
                </a>
              </li>
              <li>
                <Link to="/contact" onClick={() => this.toggleNav()}>
                  Contact
                </Link>
                <Link to="/faq" onClick={() => this.toggleNav()}>
                  FAQ
                </Link>
              </li>
            </ul>
          </nav>

          <footer className="menu-footer">
            <nav className="footer-nav">
              <ul>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC3xfvJNMZcd_oNUpkDXUqYg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="iconReseau"
                      icon={faYoutube}
                      id="youtube"
                    />
                    Youtube
                  </a>
                </li>
                <li>
                  <a
                    href="https://fr-fr.facebook.com/ApiagoHive/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="iconReseau"
                      icon={faFacebookF}
                      id="Facebook"
                    />
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/apiago/?hl=fr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="iconReseau"
                      icon={faInstagram}
                      id="insta"
                    />
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/apiago"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="iconReseau"
                      icon={faLinkedinIn}
                      id="Linkedin"
                    />
                    Linkedin
                  </a>
                </li>
              </ul>
            </nav>
          </footer>
        </div>
      </>
    );
  }
}

export default Menu;
