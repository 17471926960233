import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Accueil from "./components/Accueil/Accueil";
import Ruche from "./components/Ruche/Ruche";
import Menu from "./components/Nav/Nav";
import Contact from "./components/Contact/Contact";
import Location from "./components/Location/Location";
import Cueilleur from "./components/Cueilleur/Cueilleur";
import Collectivite from "./components/Collectivite/Collectivite";
import Equipe from "./components/Equipe/Equipe";
import Shop from "./components/Shop/Shop";
import Panier from "./components/Panier/Panier";
import Merci from "./components/Merci/Merci";
import Apiago from "./components/Produits/Apiago";
import Chalet from "./components/Produits/Chalet";
import Essaim from "./components/Produits/Essaim";
import Oups from "./components/Oups/Oups";
import Mentions from "./components/Mentions/Mentions";
import Cgv from "./components/Mentions/Cgv";
import Faq from "./components/Faq/Faq";
import CookieConsent from "react-cookie-consent";

// css
import "bootstrap/dist/css/bootstrap.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "./assets/css/custom.css";
import "./assets/css/responsive.css";
import "./assets/css/nav.css";
// autre

import { loadReCaptcha } from "react-recaptcha-google";
import Particulier from "./components/Particulier/Particulier";

class App extends React.Component {
  componentDidMount() {
    loadReCaptcha();
  }

  render() {
    return (
      <Router basename="/">
        {/* appel du menu */}
        <Menu />
        {/* <MessengerCustomerChat
          pageId="<PAGE_ID>"
          appId="<APP_ID>"
          htmlRef="<REF_STRING>"
        /> */}
        <CookieConsent
          enableDeclineButton
          declineButtonText="Non merci!"
          flipButtons
          location="bottom"
          buttonText="J'accepte! bzz bzz"
          cookieName="myAwesomeCookieName2"
          expires={1}
          style={{ background: "#383838" }}
          buttonStyle={{
            color: "#383838",
            fontSize: "13px",
            backgroundColor: "#a3c6b1",
          }}
          expires={150}
        >
          Ce site utilise des cookies pour améliorer l'expérience utilisateur.{" "}
          <span style={{ fontSize: "10px" }}>
            Mais ils ne font pas grossir, promis
          </span>
        </CookieConsent>

        {/* création des routes */}
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={Accueil}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ruche`}
            component={Ruche}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/location-et-parrainage`}
            component={Location}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/particulier`}
            component={Particulier}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/entreprise-et-collectivite`}
            component={Collectivite}
          />
          {/* <Route
            exact
            path={`${process.env.PUBLIC_URL}/Entreprises`}
            component={Entreprises}
          /> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/cueilleur`}
            component={Cueilleur}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/equipe`}
            component={Equipe}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/panier`}
            component={Panier}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/la-ruche-apiago`}
            component={Apiago}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/la-ruche-chalet`}
            component={Chalet}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/essaim-abeilles-noires`}
            component={Essaim}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/magasin`}
            component={Shop}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/oups`}
            component={Oups}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/mentions`}
            component={Mentions}
          />
          <Route exact path={`${process.env.PUBLIC_URL}/cgv`} component={Cgv} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/merci`}
            component={Merci}
          />
          <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
        </Switch>
      </Router>
    );
  }
}

export default App;
