//base
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
//bibliothèques
import ReactFullpage from "@fullpage/react-fullpage";
import "fullpage.js/vendors/scrolloverflow";
import { Col, Row, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import bg from "../../assets/img/background/fleur2.jpg";

import sample from "../../assets/video/home.mp4";
//Carousel
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
SwiperCore.use([Pagination, Autoplay]);

// Utilisation fullPage
class Accueil extends React.Component {
  onLeave(origin, destination, direction) {
    console.log("Leaving section " + origin.index);
  }
  afterLoad(origin, destination, direction) {
    console.log("After load: " + destination.index);
  }
  render() {
    return (
      <>
        <div className="fond">
          <video
            className="videoTag"
            autoPlay
            loop
            muted
            poster={bg}
            playsinline="true"
            disablePictureInPicture="true"
          >
            <source src={sample} type="video/mp4" />
          </video>
        </div>
        <ReactFullpage
          responsiveWidth={1000}
          navigation={true}
          afterLoad={this.afterLoad.bind(this)}
          render={({ state, fullpageApi }) => {
            return (
              <div id="fullpage-wrapper">
                {/* Section 1*/}

                <div className="section">
                  <Container>
                    <div className="text-red">
                      <h1 className="font-italic text-uppercase font-26px sm-font-16px">
                        Notre engagement pour<br></br>
                        <span className="font-60px sm-font-26px font-bold font-normal text-uppercase">
                          les pollinisateurs
                        </span>
                      </h1>
                      <Col lg="6" md="12">
                        <p className="font-16px">
                          <br></br>
                          Apiago<sup>®</sup> vous aide à apporter{" "}
                          <strong>le gîte et le couvert </strong> nécessaires à
                          la <strong>préservation des pollinisateurs </strong>
                          dans votre environnement.
                          <br></br>
                          <br></br>
                          Sites industriels, entreprises avec ou sans espaces
                          verts, collectivités ou particuliers,{" "}
                          <strong>nous avons les solutions </strong> adaptées
                          pour tous.
                        </p>
                      </Col>
                    </div>
                  </Container>
                </div>

                {/* Section 2*/}
                <div className="section">
                  <Container>
                    <div className="text-light-blue">
                      <h2 className="font-italic text-uppercase font-26px sm-font-20px">
                        NOS CONVICTIONS
                        <br></br>
                        <span className="font-60px sm-font-26px font-bold font-normal">
                          CHEZ APIAGO
                        </span>
                      </h2>
                      <Col lg="6" md="12">
                        <p className="font-16px">
                          <br></br>
                          Soyons honnêtes : se contenter d’installer des ruches
                          en disant que l’on sauve les abeilles n’est pas vrai.
                          Cela peut même être néfaste à la
                          <strong> biodiversité</strong>.<br></br> Nous sommes
                          convaincus d'être nombreux à vouloir remettre du sens
                          dans les actions menées en faveur de la préservation
                          de la biodiversité.
                          <br></br>{" "}
                          <strong>
                            Agissez de façon pertinente pour les pollinisateurs{" "}
                          </strong>
                          et laissez de côté l’écoblanchiment. Quitte à faire
                          quelque chose, autant que cela soit utile !<br></br>
                        </p>
                        <Link to="/equipe" className="font-bold text-blue-link">
                          Découvrez qui nous sommes{" "}
                          <FontAwesomeIcon
                            className="iconAccueil align-self-right"
                            icon={faChevronRight}
                          />
                        </Link>
                        <br></br>
                        <br></br>
                        <p className="font-16px">
                          Les espaces industrialisés et urbanisés présentent un
                          potentiel énorme pour accueillir et favoriser le
                          développement des pollinisateurs.
                        </p>
                        <Link
                          to="/Entreprise-et-collectivite"
                          className="font-bold text-blue-link"
                        >
                          Participez, vous aussi, à des actions concrètes{" "}
                          <FontAwesomeIcon
                            className="iconAccueil align-self-right"
                            icon={faChevronRight}
                          />
                        </Link>
                      </Col>
                    </div>
                  </Container>
                </div>
                {/* Section 3*/}
                <div className="section">
                  <Container>
                    <div className="text-blue">
                      <h3 className="font-italic text-uppercase font-26px sm-font-20px">
                        RÉVÉLEZ LE POTENTIEL
                        <br></br>
                        <span className="font-60px sm-font-26px font-bold font-normal">
                          DES ESPACES URBAINS ET INDUSTRIELS
                        </span>
                      </h3>
                      <Col lg="6" md="12">
                        <p className="font-16px">
                          <br></br>
                          Apiago vous accompagne dans la transformation de votre
                          environnement afin de
                          <strong>
                            {" "}
                            permettre aux pollinisateurs de le réinvestir et de
                            s’y développer.
                          </strong>
                          <br></br>
                          <br></br>
                          Que vous soyez un{" "}
                          <strong>
                            site industriel, une entreprise avec ou sans espaces
                            verts ou une collectivité
                          </strong>
                          , nous portons des projets ayant un réel intérêt pour
                          les pollinisateurs. Nous impliquons également vos
                          salariés, collaborateurs, clients ou administrés dans
                          votre démarche engagée pour la biodiversité.
                        </p>
                        <Link
                          to="/Entreprise-et-collectivite"
                          className="font-bold text-blue-link"
                        >
                          Découvrez notre offre{" "}
                          <FontAwesomeIcon
                            className="iconAccueil align-self-right"
                            icon={faChevronRight}
                          />
                        </Link>
                      </Col>
                    </div>
                  </Container>
                </div>

                {/* Section 4*/}
                <div className="section">
                  <Container>
                    <div className="text-light-blue">
                      <h4 className="font-italic text-uppercase font-26px sm-font-20px">
                        NOTRE RUCHE
                        <br></br>
                        <span className="font-60px sm-font-26px font-bold font-normal">
                          DE BIODIVERSITÉ
                        </span>
                      </h4>
                      <Col lg="6" md="12">
                        <p className="font-16px">
                          <br></br>
                          <strong>
                            Étendard d’une action engagée pour la sauvegarde des
                            pollinisateurs
                          </strong>{" "}
                          et véritable ruche de biodiversité, la ruche Apiago
                          permet de{" "}
                          <strong>
                            préserver la nature sauvage de l’abeille mellifère.
                          </strong>
                          <br></br>
                          Installée en hauteur et connectée, elle permet de{" "}
                          <strong>
                            redonner à l’abeille mellifère la place qu’elle
                            occupe naturellement dans notre environnement.
                          </strong>
                        </p>
                        <br></br>
                        <Link
                          to="/Ruche"
                          className="font-bold text-light-blue-link"
                        >
                          En savoir plus sur la ruche Apiago{" "}
                          <FontAwesomeIcon
                            className="iconAccueil"
                            icon={faChevronRight}
                          />
                        </Link>
                        <br></br>
                        <br></br>
                        <a href="https://apiago.fr/taberna" className="font-bold text-light-blue-link">
                          Acquérir une ruche Apiago{" "}
                          <FontAwesomeIcon
                            className="iconAccueil"
                            icon={faChevronRight}
                          />
                        </a>
                      
                      </Col>
                    </div>
                  </Container>
                </div>
                {/* Section 4*/}
                <div className="section">
                  <Container>
                    <div className="text-red">
                      <h5 className="font-italic text-uppercase font-26px sm-font-20px">
                        ILS PARLENT D'APIAGO
                      </h5>
                      <br></br>
                      <Col lg="6" md="12">
                        <Swiper
                          style={{ cursor: "grab" }}
                          pagination={true}
                          autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                          }}
                        >
                          <SwiperSlide>
                            <a
                              href="https://www.facebook.com/ApiagoHive/photos/a.520307115163503/520307095163505/?type=3&theater"
                              className="text-red-link font-bold font-18px"
                            >
                              Concours Lépine
                            </a>
                            <br></br>
                            <div className="font-16px">
                              <p>
                                "Récompensée 4 fois au concours Lépine!"
                                <br></br>
                                Médaille d'argent du concours Lépine,<br></br>
                                Prix Gesica "Réseau international d'avocats",
                                <br></br> Prix spécial coup de cœur Fidealis,
                              </p>
                              <p>
                                Médaille d'or du ministère de l'agriculture".
                              </p>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <a
                              href="https://www.zemiceurope.com/fr/case-studies/capteurs-de-force-Zemic-dans-ses-ruches-intelligentes/"
                              className="text-red-link font-bold font-18px"
                            >
                              Zemic europe
                            </a>
                            <br></br>
                            <div className="font-16px">
                              <p>
                                "Apiago intègre les capteurs de force Zemic dans
                                ses ruches intelligentes.."
                              </p>
                              <p>
                                <a
                                  href="https://www.zemiceurope.com/fr/case-studies/capteurs-de-force-Zemic-dans-ses-ruches-intelligentes/"
                                  className="text-red-link font-bold font-16px"
                                >
                                  Lire l'article
                                </a>
                              </p>
                              <br></br>
                            </div>
                          </SwiperSlide>

                          <SwiperSlide>
                            <a
                              href="https://facebook.com/watch/?v=316993322309734"
                              className="text-red-link font-bold font-18px"
                            >
                              Canal Vienne
                            </a>
                            <br></br>
                            <div className="font-16px">
                              <p>
                                « Fière d'avoir eu la chance d'interviewer
                                Clément Minière de l'incroyable société Apiago "
                              </p>
                            </div>
                          </SwiperSlide>

                          <SwiperSlide>
                            <a
                              href="https://www.linternaute.com/bricolage/magazine/1299726-foire-de-paris-les-droles-d-invention-du-concours-lepine-2018/1792107-une-ruche-connectee"
                              className="text-red-link font-bold font-18px"
                            >
                              L'internaute
                            </a>
                            <br></br>
                            <div className="font-16px">
                              <p>
                                « Sa startup Apiago ambitionne de faciliter
                                l'accueil des abeilles par les particuliers à
                                l'aide d'une ruche connectée et fonctionnant par
                                énergie solaire.  »
                                <br></br>
                              </p>
                              <p>© Cécile Debise</p>
                            </div>
                          </SwiperSlide>

                          <SwiperSlide>
                            <a
                              href=""
                              className="text-red-link font-bold font-18px"
                            >
                              Francebleu.fr
                            </a>
                            <br></br>
                            <div className="font-16px">
                              <p>
                                « Deux inventions ont retenu notre attention. Il
                                s’agit d’une ruche connectéee
                                <br></br>
                                et d’un lampadaire anti-pollution. Nos deux
                                coups de cœur participent, parmi 500 inventions,
                                au concours Lépine<br></br>
                                organisé cette année à la Foire de Paris. »
                                <br></br>
                              </p>
                              <p>La Rédaction France Bleu Paris Région.</p>
                              <br></br>
                            </div>
                          </SwiperSlide>

                          <SwiperSlide>
                            <a
                              href="https://www.sudradio.fr/la-vie-en-vrai-2019-05-0"
                              className="text-red-link font-bold font-18px"
                            >
                              Sud radio
                            </a>
                            <br></br>
                            <div className="font-16px">
                              <p>
                                « Les lauréats du concours Lépine sont dévoilés
                                ce mardi, on espère que notre chouchou du jour
                                Clément Minière sera l'un d'eux ! »
                              </p>
                            </div>
                          </SwiperSlide>
                        </Swiper>
                      </Col>
                      <hr width="50%" color="#82293B" align="left"></hr>
                      <br></br>
                      <p className="text-red font-bold font-20px">
                        On se retrouve là-bas !
                      </p>
                      <Col md="12">
                        <a
                          href="https://fr-fr.facebook.com/ApiagoHive/"
                          target="_blank"
                          rel="noreferrer"
                          className="text-red-link font-18px"
                        >
                          <FontAwesomeIcon
                            className="iconReseau"
                            icon={faFacebookF}
                            id="Facebook"
                          />{" "}
                          Facebook
                        </a>
                        <br></br>
                        <a
                          href="https://www.youtube.com/channel/UC3xfvJNMZcd_oNUpkDXUqYg"
                          target="_blank"
                          rel="noreferrer"
                          className="text-red-link font-18px"
                        >
                          <FontAwesomeIcon
                            className="iconReseau"
                            icon={faYoutube}
                            id="youtube"
                          />{" "}
                          Youtube
                        </a>
                        <br></br>
                        <a
                          href="https://www.instagram.com/apiago/?hl=fr"
                          target="_blank"
                          rel="noreferrer"
                          className="text-red-link font-18px"
                        >
                          <FontAwesomeIcon
                            className="iconReseau"
                            icon={faInstagram}
                            id="insta"
                          />{" "}
                          Instagram
                        </a>
                        <br></br>
                        <a
                          href="https://www.linkedin.com/company/apiago"
                          target="_blank"
                          rel="noreferrer"
                          className="text-red-link font-18px"
                        >
                          <FontAwesomeIcon
                            className="iconReseau"
                            icon={faLinkedinIn}
                            id="Linkedin"
                          />{" "}
                          Linkedin
                        </a>
                      </Col>
                    </div>
                  </Container>
                </div>

                {/* footer */}
                <div className="section section fp-auto-height">
                  <Footer />
                </div>
              </div>
            );
          }}
        />
      </>
    );
  }
}

export default Accueil;
