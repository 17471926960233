import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
//css
import "bootstrap/dist/css/bootstrap.css";
// import "./Produits.css";
//redux
import { connect } from "react-redux";
import { addToCart } from "../../redux/actions/cartActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faBox } from "@fortawesome/free-solid-svg-icons";

import essaim1 from "../../assets/img/magasin/essaim/essaim1.jpg";
import essaim2 from "../../assets/img/magasin/essaim/essaim2.jpg";
import essaim3 from "../../assets/img/magasin/essaim/essaim3.jpg";

const Essaim = (props) => {
  const [clicked, setCLicked] = useState(null);
  const [img, setImg] = useState(essaim1);

  const submit = () => {
    props.addToCart(2);
    setCLicked(true);
  };

  return (
    <>
      <Container>
        <Row>
          <Col lg="12">
            <p className="text-uppercase">
              <Link to="/magasin">
                <p className="text-red font-bold">Produits&nbsp;/&nbsp;</p>
              </Link>
              Essaim d'abeilles noires
            </p>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col lg="6">
            <Row>
              <Col md="12" className="img-active">
                <img className="img-responsive" src={img} />
              </Col>
            </Row>
            <Row className="d-flex">
              <Col className="col-4 img-produit">
                <img
                  className="img-responsive"
                  src={essaim1}
                  onClick={() => setImg(essaim1)}
                />
              </Col>
              <Col className=" col-4 img-produit">
                <img
                  className=" img-responsive"
                  src={essaim2}
                  onClick={() => setImg(essaim2)}
                />
              </Col>
              <Col className="col-4 img-produit">
                <img
                  className=" img-responsive"
                  src={essaim3}
                  onClick={() => setImg(essaim3)}
                />
              </Col>
            </Row>
          </Col>

          <Col lg="6">
            <h1 className="text-red font-bold mt-10">
              Essaim d'abeilles noires
            </h1>
            <h3 className="text-red mt-10">189 €</h3>
            <p className=" font-bold">Vendu sur 6 cadres Dadant.</p>
            <div className="description">
              <p>
                Vous avez des connaissances en apiculture et souhaitez créer ou
                compléter un rucher avec une ruche d’abeilles endémiques ?
                <br></br>
                <br></br>
                Nos abeilles noires sont prêtes à être installées dans une ruche
                Dadant. Vous venez les récupérer entre mai et juin à Condrieu
                (69420) ou alentours (date confirmée ultérieurement, dépendant
                de la météo) dans leur ruchette.
              </p>
            </div>

            <button
              className={[clicked ? "clicked cart-button" : "cart-button"]}
              onClick={() => submit()}
            >
              <span className="add-to-cart"> Ajouter au panier</span>
              <span className="added">Ajouté</span>
              <FontAwesomeIcon
                icon={faShoppingCart}
                className="fa-shopping-cart"
              />
              <FontAwesomeIcon icon={faBox} className="fa-box" />
            </button>
          </Col>
        </Row>
      </Container>
      <br></br>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Essaim);
