import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
//css

// import "./Produits.css";
//redux
import { connect } from "react-redux";
import { addToCart } from "../../redux/actions/cartActions";
//img
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faBox } from "@fortawesome/free-solid-svg-icons";
import apiago1 from "../../assets/img/magasin/apiago/apiago1.jpg";
import apiago2 from "../../assets/img/magasin/apiago/apiago2.jpg";
import apiago3 from "../../assets/img/magasin/apiago/apiago3.jpg";

const Apiago = (props) => {
  const [cp, setCp] = useState("");
  const [clicked, setCLicked] = useState(null);
  const [clicked2, setCLicked2] = useState(null);
  const [isOk, setIsOk] = useState("precommande");
  const [img, setImg] = useState(apiago1);

  const checkCp = () => {
    if (cp.length > 3) {
      const value = cp[0] + cp[1];
      const val = value.toString();
      if (
        val == "07" ||
        val == "26" ||
        val == "38" ||
        val == "42" ||
        val == "43" ||
        val == "69"
      ) {
        setIsOk("oui");
      } else if (val <= "95") {
        setIsOk("precommande");
      } else {
        setIsOk("non");
      }
    }
  };

  const submit = (id) => {
    props.addToCart(id);
    setCLicked(true);
  };
  const submit2 = () => {
    props.addToCart(5);
    setCLicked2(true);
  };

  return (
    <>
      <Container>
        <Row className="d-block">
          <Col lg="12">
            <p className="text-uppercase">
              <Link to="/magasin">
                <p className="text-red font-bold">Produits&nbsp;/&nbsp;</p>
              </Link>
              La ruche Apiago
            </p>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col lg="6" md="12">
            <Row>
              <Col lg="12" md="12" className="img-active">
                <img className="img-responsive" src={img} />
              </Col>
            </Row>
            <Row className="d-flex">
              <Col className=" img-produit" onClick={() => setImg(apiago1)}>
                <img className=" img-responsive" src={apiago1} />
              </Col>
              <Col className="img-produit" onClick={() => setImg(apiago2)}>
                <img className=" img-responsive" src={apiago2} />
              </Col>
              <Col className=" img-produit" onClick={() => setImg(apiago3)}>
                <img className=" img-responsive" src={apiago3} />
              </Col>
            </Row>
          </Col>

          <Col lg="6">
            <h1 className="text-red font-bold mt-10">La ruche Apiago</h1>
            <h3 className="text-red mt-10">2650 €</h3>
            <p className="font-bold">
              La ruche Apiago, une ruche de biodiversité.
            </p>
            <div className="font-16px">
              <p>
                Elle permet d’accueillir chez vous des abeilles mellifères non
                sélectionnées génétiquement pour les besoins de l’exploitation
                par l’Homme.<br></br> En France, la ruche Apiago est peuplée
                d’abeilles noires, variété endémique dont la population à l’état
                sauvage n’est malheureusement plus qu’anecdotique. Redonnez-leur
                un espace de vie sauvage chez vous grâce à cette ruche.
                <br></br>
                <br></br>
                <strong>
                  Vous pouvez précommander votre ruche qui sera produite et
                  livrée au printemps 2022 en France métropolitaine.
                </strong>
                <br></br> Cette précommande vous permet de bénéficier de la{" "}
                <strong> livraison et installation offertes </strong> ainsi que
                de <strong> 2 années de garantie complète </strong>(abeilles,
                pièces, main d’œuvre et déplacement)
                <br></br>
                <br></br>Vous ne payez maintenant qu’un{" "}
                <strong>
                  acompte de réservation de 30 % du prix de la ruche
                </strong>
                . Le paiement du solde ne vous sera demandé qu’au printemps
                2022, afin de valider l’expédition de votre ruche.
                <br></br>
                <br></br>Dans le cadre du salon du Made in France 2021, les
                précommandes réalisées du{" "}
                <strong>11 au 14 novembre 2021 </strong>bénéficient
                exceptionnellement du{" "}
                <strong>module de récolte de miel offert</strong> l’année
                suivant l’installation de la ruche.
              </p>
            </div>
            {/* 
            <form>
              <fieldset>
                <legend>
                  Code postal du lieu d'installation de la ruche Apiago :
                </legend>
                <form className="">
                  <div className="cp-block">
                    <input
                      onChange={(e) => setCp(e.target.value)}
                      type="number"
                      className="cp-form"
                      id="text"
                      name="text"
                      onKeyPress={() => checkCp()}
                      maxLength="5"
                      required
                    />
                  </div>
                  <p id="msg" className="msg"></p>
                </form>
              </fieldset>
            </form> */}
            {/* <p className={isOk == "precommande" ? "" : "hidden"}>
              Vous êtes éligible à la précommande pour une livraison au
              printemps 2022. <br></br>Vous bénéficiez de conditions
              avantageuses. Offre à durée limitée ; voir détails ci-après.
            </p>
            <p className={isOk == "non" ? "" : "hidden"}>
              Oups, vous semblez ne pas être éligible aux offres actuelles.
              <br></br> Vous pouvez cependant nous contacter directement pour
              plus de détails.
            </p> */}
            {/* 
            <button
              className={[
                isOk == "oui" || isOk == "precommande"
                  ? clicked
                    ? "clicked cart-button"
                    : "cart-button"
                  : "hidden",
              ]}
              onClick={() => submit(isOk == "oui" ? 1 : 4)}
            >
              {isOk == "oui" ? (
                <span className="add-to-cart"> Ajouter au panier</span>
              ) : (
                <span className="add-to-cart"> Précommander</span>
              )}
              <span className="added">Ajouté</span>
              <FontAwesomeIcon
                icon={faShoppingCart}
                className="fa-shopping-cart"
              />
              <FontAwesomeIcon icon={faBox} className="fa-box" />
            </button> */}
            <button
              className={[
                isOk == "oui" || isOk == "precommande"
                  ? clicked
                    ? "clicked cart-button"
                    : "cart-button"
                  : "hidden",
              ]}
              onClick={() => submit(isOk == "oui" ? 1 : 4)}
            >
              {isOk == "oui" ? (
                <span className="add-to-cart"> Ajouter au panier</span>
              ) : (
                <span className="add-to-cart"> Précommander</span>
              )}
              <span className="added">Ajouté</span>
              <FontAwesomeIcon
                icon={faShoppingCart}
                className="fa-shopping-cart"
              />
              <FontAwesomeIcon icon={faBox} className="fa-box" />
            </button>{" "}
          </Col>
        </Row>

        <Row className="marge-top">
          <Col lg="12">
            <h4 className="red">Précisions importantes</h4>
            <p>
              La ruche Apiago sera installée chez vous (abeilles comprises entre
              début juin et fin septembre au plus tard.
              <br></br> <br></br>
              Aucune connaissance préalable en apiculture requise pour faire
              l’acquisition de cette ruche de biodiversité.
              <br></br>
              Précisions importantes Vous devez remplir les conditions suivantes
              :<br></br>- l’adresse d’installation doit être en France
              métropolitaine
              <br></br> - avoir défini un emplacement d’installation en hauteur,
              sur une partie d’un mur exposé au soleil a minima pendant la
              moitié de la journée
              <br></br> - Le mur sur lequel sera installée la ruche doit être en
              pierre, béton (plein ou creux type parpaings) brique de terre
              cuite (pleine ou creuse).
              <br></br> <br></br> Pour les murs avec isolation par l’extérieur,
              vous devez ajouter au panier le supplément spécifique
              (ci-dessous).
              <Col lg="12">
                <button
                  className={[
                    isOk == "oui" || isOk == "precommande"
                      ? clicked2
                        ? "clicked cart-button"
                        : "cart-button"
                      : "hidden",
                  ]}
                  onClick={() => submit2()}
                >
                  <span className="add-to-cart">Je prends le supplément</span>
                  <span className="added">Ajouté</span>
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    className="fa-shopping-cart"
                  />
                  <FontAwesomeIcon icon={faBox} className="fa-box" />
                </button>
              </Col>
              <br></br>
              <br></br>
            </p>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Apiago);
