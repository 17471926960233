//base
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

//bibliothèques
import ReactFullpage from "@fullpage/react-fullpage";
import "fullpage.js/vendors/scrolloverflow";
import { Col, Row, Container, Button } from "react-bootstrap";
import FaqComponent from "react-faq-component";
//images

// Utilisation fullPage
const Faq = () => {
  const data1 = {
    title: "Questions les plus posées",
    rows: [
      {
        title:
          "Je n'y connais absolument rien aux abeilles. Est-il raisonnable d'installer cette ruche chez moi ?",
        content:
          "La ruche Apiago est conçue de telle sorte qu'il n'y a aucune connaissance à avoir sur les abeilles. Cette ruche est tout particulièrement adaptée pour les novices du monde des abeilles ",
      },
      {
        title:
          "Je crains pour moi ou mes proches les piqûres d'abeilles. Avoir une ruche augmente-t-il ce risque ?",
        content:
          "Une ruche traditionnelle est placée au sol. Cela peut alors effectivement présenter un risque pour les personnes non averties. La ruche Apiago est située à plusieurs mètres de hauteur et lorsqu'elles butinent, les abeilles ne présentent aucune agressivité. Vous aurez un plus grand nombre d'abeilles autour de chez vous mais aucune ne viendra vous déranger et encore moins vous piquer. De plus, les abeilles ne sont pas attirées par ce qui se trouve sur votre table (contrairement à certaines guêpes)",
      },
      {
        title: "Pourquoi choisir la ruche Apiago ?",
        content:
          "Notre ruche est unique, protégée par des brevets. Nous sommes les seuls à vous proposer un produit adapté au grand -public, ne nécessitant aucune connaissance préalable et pouvant être installé facilement chez vous. La ruche Apiago n’est produite qu’avec des composants et matériaux de qualité, conçus pour durer. La ruche Apiago est conçue de telle sorte que tous les composants puissent être remplacés simplement et à un prix raisonnable… comme vous, nous n’aimons pas avoir à racheter un produit neuf lorsqu’une petite réparation est suffisante. Il n’y a aucun abonnement obligatoire et vous bénéficierez toujours gratuitement des mises-à-jour de l’application dédiée. Nous assurons un service après-vente réactif et de qualité. Par votre achat vous contribuez à la préservation des abeilles et à la biodiversité. Votre achat profite également à la fondation Raspberry qui facilite dans le monde l’accès à l’informatique pour les plus défavorisés et en développe l’enseignement.",
      },
    ],
  };

  const data2 = {
    title: "La ruche Apiago",
    rows: [
      {
        title:
          "Je n’aurai pas beaucoup de temps à leur consacrer, est-il raisonnable d’installer cette ruche ?",
        content:
          "La ruche Apiago est conçue de telle sorte qu’elle est complètement autonome sur les actions basiques nécessaires à la bonne santé des abeilles. Si des traitements contre des maladies sont nécessaires pour garantir la survie de votre ruche, ceux-ci vous seront conseillés automatiquement par l’application associée. Cela ne vous prendra pas plus de 10 minutes.",
      },
      {
        title: "Puis-je récolter du miel de ma ruche ?",
        content:
          "Oui, il est possible de récolter du miel produit par les abeilles d’une ruche Apiago. S’agissant d’une ruche de biodiversité, les quantités prélevées sont moins importantes que sur des ruches spécifiquement prévues pour la production de miel. Récolter du miel dans une ruche n’est pas obligatoire, vous pouvez laisser aux abeilles l’ensemble de leur production.",
      },
    ],
  };
  const data3 = {
    title: "Les essaims d'abeilles",
    rows: [
      {
        title:
          "Si mon essaim vient à disparaître, par exemple pour cause d’intoxication aux pesticides ?",
        content:
          "La ruche Apiago permet, comme les ruches traditionnelles, de retirer les cadres laissés par la colonie disparue et de les remplacer par de nouveaux, avec un nouvel essaim. La désinfection de la ruche est… automatique ;-) Et parce qu’on n’aime pas les designs développés pour de la vente forcée de produits spécifiques, la ruche Apiago permet l’utilisation de cadres standards. Ainsi, vous pouvez acheter un essaim à coût classique (entre 100 et 170 € en moyenne) par vos propres moyens ou via notre site (nous ne faisons aucun bénéfice sur la vente d’essaim et développons un réseau d’apiculteurs partenaires)",
      },
      {
        title:
          "Peut-on être attaqué par un essaim d’abeilles même si on ne touche pas à la ruche ?",
        content:
          "Non, les abeilles n’ont pas le même comportement que celui du frelon.",
      },
    ],
  };

  return (
    <>
      <div class="fondShop"></div>
      <Container>
        <Row className="mx-auto my-auto">
          <Col md="10" className="mx-auto mt-10">
            <h1 className="text-center text-red font-bold font-40px text-uppercase">
              Foires au questions
            </h1>
            <p>
              La ruche Apiago, produit nouveau, produit unique. Nous nous
              efforçons de répondre ici à vos principales interrogations.
              N’hésitez pas à nous contacter si vous ne trouvez pas votre
              réponse ici.
            </p>
          </Col>
        </Row>
        <Row className="mt-10 mb-10">
          <Col>
            <FaqComponent data={data1} />
          </Col>
        </Row>
        <Row className="mt-10 mb-10">
          <Col>
            <FaqComponent data={data2} />
          </Col>
        </Row>
        <Row className="mt-10 mb-10">
          <Col>
            <FaqComponent data={data3} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Faq;
