import React from "react";
import Footer from "../Footer/Footer";

//img
import img1 from "../../assets/img/essaims/img1.jpg";
import img2 from "../../assets/img/essaims/img2.jpg";
import img3 from "../../assets/img/essaims/img3.jpg";

//bibliothèque
import { Col, Row, Container } from "react-bootstrap";
//Carousel
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper/core";
SwiperCore.use([Autoplay]);

class Cueilleur extends React.Component {
  render() {
    return (
      <>
        <Container>
          <Row className="d-flex m-block">
            <Col
              lg="6"
              md="12"
              className="align-self-center justify-content-md-center containerCarousel"
              style={{ overflow: "hidden" }}
            >
              <div className="carousel-cueilleur">
                <Swiper
                  loop={true}
                  loopFillGroupWithBlank={true}
                  direction={"vertical"}
                  slidesPerView={2.5}
                  spaceBetween={10}
                  centeredSlides={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                >
                  <SwiperSlide>
                    <img src={img1} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={img2} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={img3} />
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>

            <Col
              lg="6"
              md="12"
              className="align-self-center mx-auto my-auto sm-mt--10 "
            >
              <h1 className="text-light-blue font-26px font-bold text-uppercase">
                QU'EST CE QUE L'ESSAIMAGE ?
              </h1>
              <br></br>
              <div>
                <p className="font-14px">
                  <strong>
                    L’essaimage est le seul mode de reproduction naturel des
                    colonies d’abeilles.
                  </strong>{" "}
                  Au cours d’un essaimage, environ la moitié des abeilles de la
                  colonie part à la recherche d’un nouveau lieu de vie.
                </p>
                <p>
                  <strong>La ruche Apiago est une ruche de biodiversité</strong>
                  . Cela implique qu’au printemps, la colonie d’abeilles va
                  vivre un ou plusieurs essaimages. Chaque essaimage constitue
                  un véritable spectacle que nous offre la nature. Quelques
                  milliers à dizaines de milliers d’abeilles sortent alors de la
                  ruche et vont aller former un essaim le temps de trouver un
                  emplacement définitif. Les abeilles sont alors inoffensives.
                </p>
                <br></br>

                <p className="font-14px">
                  <strong className="text-light-blue font-20px">
                    UN ESSAIM VOUS GÈNE ?
                  </strong>
                  <br></br>
                  <strong>
                    Les abeilles mellifères font partie de notre environnement
                    depuis des centaines de milliers d’années
                  </strong>
                  . Si une colonie d’abeilles s’installe à un emplacement ne
                  posant aucun problème, vous pouvez faire le choix de la
                  laisser y continuer sa vie. Dans le cas contraire, vous pouvez
                  faire appel à un cueilleur d’essaims.
                </p>

                <a
                  className="text-light-blue-link"
                  href="http://www.essaim-abeilles.org/carte-cueilleurs-essaims/"
                  target="_blank"
                >
                  Trouvez un cuilleurs d'essaim près de chez vous
                </a>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid style={{ marginTop: 100 }}>
          <Footer />
        </Container>
      </>
    );
  }
}
export default Cueilleur;
