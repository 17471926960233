//base
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

//bibliothèques
import ReactFullpage from "@fullpage/react-fullpage";
import "fullpage.js/vendors/scrolloverflow";
import { Col, Row, Container, Button } from "react-bootstrap";
//images

// Utilisation fullPage
class Location extends React.Component {
  onLeave(origin, destination, direction) {
    console.log("Leaving section " + origin.index);
  }
  afterLoad(origin, destination, direction) {
    console.log("After load: " + destination.index);
  }
  render() {
    return (
      <>
        <div class="fondShop"></div>
        <Container>
          <Row className="mt-10 mb-10">
            <Col md="6" className="font-14px">
              <h1 className="font-italic text-uppercase font-20px text-red">
                Votre soutien<br></br>
                <span className="font-26px font-bold font-normal text-uppercase">
                  aux pollinisateur
                </span>
              </h1>
              <p>
                <strong>
                  Vous avez vu une ruche Apiago installée dans un lieu
                  accueillant du public ?
                </strong>
              </p>
              <p>
                <strong>
                  Cette ruche de biodiversité est l’étendard d’une démarche
                  engagée en faveur de la préservation des pollinisateurs
                </strong>
                .<br></br>
                <br></br>
                Installer une ruche de biodiversité tout en recréant des espaces
                naturels, sources de nourriture pour l’ensemble des espèces
                indigènes (dont l’abeille mellifère fait partie, pour peu qu’il
                s’agisse de la bonne espèce) démontre une véritable connaissance
                des enjeux actuels.
                <br></br>
                <br></br>Vous pouvez également{" "}
                <strong>apporter votre contribution</strong> à cette démarche en
                la parrainant.
                <br></br>
                <br></br>
                <strong>
                  Les fonds ainsi récoltés permettent d’apporter le gîte et le
                  couvert aux pollinisateurs endémiques
                </strong>
                .<br></br>
                <br></br>En récompense de votre parrainage, vous recevrez des
                <strong>
                  pots d’un miel produit par des apiculteurs indépendants
                </strong>
                .
              </p>
            </Col>
            <Col md="6" className="my-auto">
              <p className="font-16px">
                Pour nous aider à vous rediriger au mieux, choisissez votre
                option
              </p>

              <h2 className="font-20px">Je suis :</h2>
              <br></br>

              <Link to="/entreprise-et-collectivite">
                <Button className="btn-location">
                  <h4 className="font-16px text-uppercase font-bold">
                    UNE COLLECTIVITÉ OU ENTREPRISE
                  </h4>
                  <p>et je souhaite proposer votre service à ma communauté</p>
                </Button>
              </Link>
              <br></br>
              <br></br>
              <Link to="/particulier">
                <Button className="btn-location">
                  <h4 className="font-16px text-uppercase font-bold">
                    UN PARTICULIER
                  </h4>
                  <p>et je souhaite parrainer une ruche de biodiversité</p>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}

export default Location;
