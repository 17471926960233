//base
import React, { Component } from "react";
import { Col, Row, Container } from "react-bootstrap";
//css
import "bootstrap/dist/css/bootstrap.css";
import Footer from "../Footer/Footer";

class Mentions extends Component {
  render() {
    return (
      <>
        <Container>
          <Row className="d-block">
            <Col>
              <p className="text-red font-26px">
                Mentions Légales du site web et de l'application mobile
              </p>
              <strong className="text-red font-20px">APIAGO</strong>
              <br></br>
              <strong>Responsable de publication</strong>
              <br></br>
              Clément MINIÈRE <br></br>
              75 Rue Nationale, 69420 Condrieu, France <br></br>
              Tel : +33 6 23 11 46 21 <br></br>
              <br></br>
              <strong>Developpement /webdesign / illustrations</strong>
              <br></br>
              Laurelyne DAMIER <br></br>
              www.laurelynedamier.fr <br></br>
              <br></br>
              <strong>Webdesign/ illustrations</strong>
              <br></br>
              Morgane Lecarre <br></br>
              https://morganelecarre.wixsite.com/portfolio.fr
              <br></br>
              <br></br>
              <strong> HEBERGEUR </strong>
              <br></br>
              OVH <br></br>2 rue Kellermann, 59100 Roubaix, France
              <br></br>
              <br></br>
              <strong className="text-red font-26px">
                Conditions d'utilisations
              </strong>
              <br></br>
              <br></br>
              Le site et chacun des éléments, y compris mais sans limitation les
              marques, les logos, icônes, infographies, photographies, qui le
              composent sont protégés au titre de la législation internationale
              de la propriété intellectuelle. Les contenus figurant sur le site
              sont la propriété d'APIAGO ou d’autres entreprises. Toute
              utilisation, reproduction ou représentation, par quelque procédé
              que ce soit, et sur quelque support que ce soit, de tout ou partie
              du site et/ou des éléments qui le composent n'est pas autorisée
              sans le consentement expresse d'APIAGO.<br></br>
              <br></br>
              <br></br>
              <strong className="text-red font-26px">Contenus </strong>
              <br></br>APIAGO met tout en œuvre pour vous offrir des
              informations claires, détaillées et fiables. Cependant, si malgré
              toute l’attention portée à la gestion de ces contenus par nos
              services vous deviez constater des inexactitudes, des manques
              d’informations ou des erreurs, nous vous invitons à en informer la
              structure, par courrier électronique via notre formulaire de
              contact ou bien par courrier postal.
              <br></br>
              <br></br>
              <strong className="text-red font-26px">
                Données personnelles (site web)
              </strong>
              <br></br>
              <br></br>
              Conformément au Règlement Général sur la Protection des Données,
              dit « RGPD », pour toute information ou exercice de vos droits
              Informatique et Libertés (dont les droits d’accès, de
              rectification ou de suppression) sur les traitements de vos
              données personnelles par la Structure, vous pouvez contacter la
              Structure par le biais du formulaire de contact de ce site ou par
              courrier postal envoyé à l’adresse indiquée sur la page de ce
              formulaire. Seules des statistiques générales de trafic sont
              collectées lors de votre navigation sur le site : nombre de
              visiteurs, nombre de visites, pays d’origine des visites, type de
              navigateur utilisé, entre autres. Ces données sont collectées
              uniquement pour permettre à la Structure d’analyser la
              fréquentation de ses pages d'information afin d'en améliorer le
              contenu. Les données relatives à la navigation des visiteurs ne
              sont pas exploitées nominativement. Les données personnelles
              recueillies sur le site résultent de la communication volontaire
              d'une adresse de courrier électronique lors du dépôt d'un message
              électronique (informations non stockées) et/ou de l’inscription à
              la lettre d’information (« newsletter ») et/ou de la création d’un
              compte pour contribuer sur le site (voir CGU de l’Espace Membre).
              Les adresses e-mail ainsi recueillies ne servent qu’à transmettre
              les éléments d’information demandés. Les adresses électroniques
              collectées ne feront l’objet d’aucune cession à des tiers ni
              d’aucun autre traitement de la part d'APIAGO. Pour la « newsletter
              », si vous souhaitez ne plus recevoir de messages de notre part
              après inscription, vous pouvez vous désinscrire en cliquant sur le
              lien en bas du prochain message que vous recevrez par voie
              électronique. Utilisation des cookies Un cookie est un petit
              fichier texte au format alphanumérique déposé sur le disque dur de
              l’internaute par le serveur du site visité ou par un serveur tiers
              (service de web analytique, etc.). Grâce aux cookies, le site
              Internet apiago.fr peut rendre la navigation plus simple et
              apporter des services complémentaires. apiago.fr utilise des
              cookies aux fins suivantes :<br></br>
              <br></br>- Suivi de l'audience du site (nombre de visiteurs, pages
              les plus vues...) grâce au service Google Analytics ;<br></br>
              <br></br> - Détection et consultation de la version mobile du site
              (apiago.fr/mobile) ;<br></br>
              <br></br> - Gestion des sessions de connexion à l'Espace Membre
              (apiago.fr/fr/mon-compte) ;<br></br>
              <br></br> - Partage des pages du site sur les réseaux sociaux
              grâce
              <br></br>
              <br></br> Le site de apiago.fr n'utilise en aucun cas les données
              récupérées par les cookies pour les réutiliser à des fins
              commerciales ou de revente d'informations privées. Vous pouvez
              choisir de désactiver les cookies dans votre navigateur en vous
              basant sur les documentations ci-dessous (à sélectionner selon
              votre navigateur web) :<br></br>
              <br></br>
              <a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies">
                Mozilla Firefox
              </a>
              <br></br>
              <a href="https://support.google.com/chrome/answer/95647?hl=fr">
                Google Chrome
              </a>
              <br></br>
              <a href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11">
                Internet Explorer
              </a>
              <br></br>
              <a href="https://help.opera.com/fr/Windows/10.20/fr/cookies.html">
                Opera
              </a>
              <br></br>
              <a href="https://support.apple.com/kb/PH17191?locale=fr_FR&viewlocale=fr_FR">
                Safari
              </a>
              <br></br>
              <a href="https://support.apple.com/fr-fr/HT201265">iOS</a>
              <br></br>
              <a href="https://www.wikihow.com/Disable-Cookies#Android_Devices">
                Android
              </a>
              <br></br>
              <a href="https://docs.blackberry.com/en/smartphone_users/deliverables/32004/Turn_off_cookies_in_the_browser_60_1072866_11.jsp">
                Windows Phone
              </a>
              <br></br>
              Attention : la désactivation des cookies risque de vous empêcher
              d’utiliser certaines fonctionnalités du site.
              <br></br>
              <br></br>
              <strong className="text-red font-26px">
                Données personnelles (mobile)
              </strong>
              <br></br>
              <br></br>
              Conformément au Règlement Général sur la Protection des Données,
              dit « RGPD », pour toute information ou exercice de vos droits
              Informatique et Libertés (dont les droits d’accès, de
              rectification ou de suppression) sur les traitements de vos
              données personnelles par la Structure, vous pouvez contacter la
              Structure par le biais du formulaire de contact de ce site ou par
              courrier postal envoyé à l’adresse indiquée sur la page de ce
              formulaire.
              <br></br>Nous collectons les renseignements suivants :<br></br>
              <br></br> - Nom/ Prénom
              <br></br> - Email
              <br></br>- Numéro de téléphone
              <br></br>- Adresse postale
              <br></br>
              <br></br> Ces données personnelles sont recueillies uniquement
              après que vous nous ayez donné votre consentement, notamment lors
              de votre inscription pour l'email, les autres informations ne sont
              pas obligatoires, libre à vous de ne pas les donner. Les
              renseignements personnels que nous collectons sont recueillis au
              travers de formulaires et grâce à l’interactivité établie entre
              vous et notre Application mobile. Vos données ne sont en aucun cas
              transmisses à des partenaires de Apiago. L'adresse ainsi que le
              numéro de téléphone ont deux fonctions, elles permettent la
              récupération de votre compte en cas de soucis et la confirmation
              de l'enregistrement d'une ruche.
              <br></br>
              <br></br>
              <strong className="text-red font-26px">Droits d'accès </strong>
              <br></br>
              Nous nous engageons à reconnaître un droit d’accès et de
              rectification aux personnes concernées désireuses de consulter,
              modifier, voire radier les informations les concernant.
              <br></br>
              <br></br>
              <strong className="text-red font-26px">Crédits </strong>
              <br></br>
              <a href=" https://www.mailerlite.com/">Mailer lite</a>
              <br></br>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}

export default Mentions;
