import React from "react";
import Footer from "../Footer/Footer";

// bibliothèque
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

class Oups extends React.Component {
  render() {
    return (
      <>
        <div className="fondM">
          <Container>
            <Row>
              <Col lg={{ span: 6, offset: 3 }} className="text-center">
                <p className="font-30px text-red font-bold mt-10 text-uppercase">
                  Oups
                </p>
                <p className="font-26px">
                  Il semblerait qu’une erreur se soit produite et que votre
                  commande n’ai pas abouti
                </p>

                <Link to="/" className="text-red btn-dark btn">
                  retour au site{" "}
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </>
    );
  }
}

export default Oups;
