import React from "react";
import { Link } from "react-router-dom";
//bibliothèques
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import ReactFullpage from "@fullpage/react-fullpage";
import { Col, Row, Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

// Utilisation fullPage
class Ruche extends React.Component {
  onLeave(origin, destination, direction) {
    console.log("Leaving section " + origin.index);
  }
  afterLoad(origin, destination, direction) {
    console.log("After load: " + destination.index);
  }
  render() {
    return (
      <ReactFullpage
        responsiveWidth={1000}
        normalScrollElements
        navigation={true}
        onLeave={this.onLeave.bind(this)}
        afterLoad={this.afterLoad.bind(this)}
        render={({ state, fullpageApi }) => {
          return (
            <div id="fullpage-wrapper">
              {/* Section 1*/}
              <div className="section">
                <Container fluid>
                  <Row>
                    <Col lg="6" md="12" className="sm-mb-10">
                      <div className="img1"></div>
                    </Col>

                    <Col
                      lg="4"
                      md="12"
                      className="align-self-center mx-auto my-auto sm-plr-10"
                    >
                      <h1 className="font-italic text-uppercase font-20px text-red">
                        Une ruche<br></br>
                        <span className="font-26px font-bold font-normal text-uppercase">
                          de biodiversité
                        </span>
                      </h1>
                      <br></br>
                      <div>
                        <p className="font-16px">
                          La ruche Apiago abrite des{" "}
                          <strong>abeilles mellifères endémiques</strong>, non
                          sélectionnées par l’Homme pour de meilleurs
                          rendements.
                        </p>
                        <p>
                          Vous souhaitez <strong>récolter du miel </strong>? La
                          ruche Apiago vous permet de{" "}
                          <strong>
                            respecter le travail et le bien-être des abeilles{" "}
                          </strong>
                          avec une récolte raisonnable qui évite d’avoir à les
                          nourrir avec du sucre.
                        </p>

                        <p>
                          L’objectif principal est la{" "}
                          <strong>
                            préservation de colonies d’abeilles vivant comme à
                            l’état sauvage.{" "}
                          </strong>
                          Cette préservation est essentielle pour la diversité
                          génétique de l’abeille mellifère. Apiago s'est donné
                          pour mission de mettre à portée de tout un chacun
                          cette <strong>apiculture de biodiversité</strong>
                        </p>

                        <p>
                          Lorsqu’elle est installée par une entreprise ou une
                          collectivité, la ruche Apiago est l’
                          <strong>
                            étendard d’une démarche engagée en faveur de la
                            préservation des pollinisateurs
                          </strong>
                          .
                        </p>
                        <br></br>
                        <Link
                          to="/Entreprise-et-collectivite"
                          className="font-bold text-light-blue-link"
                        >
                          En savoir plus sur la démarche des entreprises et
                          collectivités{" "}
                          <FontAwesomeIcon
                            className="iconAccueil"
                            icon={faChevronRight}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* Section 2*/}
              <div className="section sm-mt-10">
                <Container fluid>
                  <Row>
                    <Col
                      lg="4"
                      md="12"
                      className="align-self-center mx-auto my-auto sm-plr-10"
                    >
                      <h2 className="font-italic text-uppercase font-20px text-light-blue">
                        Une ruche<br></br>
                        <span className="font-26px font-bold font-normal text-uppercase">
                          à la portée <br></br>de tout un chacun
                        </span>
                      </h2>
                      <br></br>
                      <div>
                        <p className="gras">
                          La ruche Apiago ne requiert pas de connaissances
                          préalables à son installation. Elle permet à tout un
                          chacun de{" "}
                          <strong>
                            participer à la préservation de la nature sauvage de
                            l’abeille mellifère en lui offrant un habitat dans
                            nos lieux de vie urbanisés.
                          </strong>
                        </p>
                        <p>
                          Les différents capteurs intégrés à la ruche Apiago
                          permettent un <strong>suivi automatique</strong>, par
                          nos soins et à distance, de la colonie d’abeilles,
                          sans que ces dernières ne soient dérangées.
                        </p>

                        <p>
                          <strong>
                            Vous découvrez ainsi le monde des abeilles en étant
                            accompagné dans votre démarche.
                          </strong>
                        </p>
                      </div>
                    </Col>
                    <Col lg="6" md="12" className="sm-mb-10">
                      <div className="img2"></div>
                    </Col>
                  </Row>
                </Container>
              </div>
              {/* Section 3*/}
              <div className="section">
                <Container fluid>
                  <Row>
                    <Col lg="6" md="12" className="sm-mb-10">
                      <div className="img3"></div>
                    </Col>

                    <Col
                      lg="4"
                      md="12"
                      className="align-self-center mx-auto my-auto sm-plr-10"
                    >
                      <div className="textBaseR">
                        <h2 className="font-italic text-uppercase font-20px text-red">
                          Une ruche <br></br>
                          <span className="font-26px font-bold font-normal text-uppercase">
                            installée en hauteur
                          </span>
                        </h2>
                        <br></br>
                        <div>
                          <p className="font-bold">
                            La ruche Apiago permet une installation là où une
                            ruche classique ne peut pas être installée.
                          </p>
                          <p>
                            À l’état naturel, les abeilles mellifères
                            choisissent de s’installer en hauteur ; la ruche
                            Apiago donne aux abeilles mellifères un{" "}
                            <strong>
                              emplacement idéal dans nos lieux de vie.
                            </strong>
                          </p>

                          <p>
                            Vous pouvez ainsi continuer vos activités en
                            dessous, comme avant, sans jamais être dérangés par
                            les allées et venues des abeilles. Le design
                            original et la{" "}
                            <strong>
                              possibilité de personnalisation de la ruche Apiago{" "}
                            </strong>
                            permettent une intégration esthétique partout
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* Section 4*/}
              <div className="section sm-mt-10">
                <Container fluid>
                  <Row>
                    <Col
                      lg="4"
                      md="12"
                      className="align-self-center mx-auto my-auto sm-plr-10"
                    >
                      <h2 className="font-italic text-uppercase font-20px text-light-blue">
                        Une ruche <br></br>
                        <span className="font-26px font-bold font-normal text-uppercase">
                          connectée
                        </span>
                      </h2>
                      <br></br>
                      <div>
                        <p className="font-bold">
                          L’application pour smartphone et tablette associée à
                          la ruche Apiago offre la possibilité de suivre la vie
                          de votre ruche et{" "}
                          <strong>
                            surveiller la santé de vos abeilles sans les
                            déranger.
                          </strong>
                        </p>
                        <p>
                          Nos algorithmes traitent les informations émises par
                          la ruche via le réseau de téléphonie mobile et
                          indiquent l’état de santé ainsi que les événements de
                          la vie de la colonie sur l’application mobile Apiago
                          associée (disponible sur iOS et Android)
                        </p>

                        <p>
                          Son panneau solaire en forme de feuille de chêne et
                          son mode de communication rendent la ruche Apiago{" "}
                          <strong>autonome et installable partout.</strong>
                        </p>

                        <p>
                          Vous pouvez également recevoir des informations
                          générales sur la vie des abeilles au fil des saisons
                          et ainsi développer vos connaissances.
                        </p>
                      </div>
                    </Col>
                    <Col lg="6" md="12" className="sm-mb-10">
                      <div className="img4" sm="12"></div>
                    </Col>
                  </Row>
                </Container>
              </div>
              {/* footer */}
              <div className="section section fp-auto-height">
                <Footer />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

export default Ruche;
