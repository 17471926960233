import React, { Component, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
//css
import "bootstrap/dist/css/bootstrap.css";
// import "./Produits.css";
//text-redux
import { connect } from "react-redux";
import { addToCart } from "../../redux/actions/cartActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faBox } from "@fortawesome/free-solid-svg-icons";

import chalet1 from "../../assets/img/magasin/chalet/chalet1.jpg";
import chalet2 from "../../assets/img/magasin/chalet/chalet2.jpg";
import chalet3 from "../../assets/img/magasin/chalet/chalet3.jpg";
import { store } from "../../index";

const Chalet = (props) => {
  const [clicked, setCLicked] = useState(null);
  const [img, setImg] = useState(chalet1);

  const submit = () => {
    props.addToCart(3);
    setCLicked(true);
  };

  return (
    <>
      <Container>
        <Row>
          <Col lg="12">
            <p className="text-uppercase">
              <Link to="/magasin">
                <p className="text-red ont-bold">Produits&nbsp;/&nbsp;</p>
              </Link>
              La ruche Chalet
            </p>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col lg="6">
            <Row>
              <Col md="12" className="img-active ">
                <img className="img-responsive" src={img} />
              </Col>
            </Row>
            <Row className="d-flex">
              <Col className="img-produit">
                <img
                  className=" img-responsive"
                  src={chalet1}
                  onClick={() => setImg(chalet1)}
                />
              </Col>
              <Col className="img-produit">
                <img
                  className=" img-responsive"
                  src={chalet2}
                  onClick={() => setImg(chalet2)}
                />
              </Col>
              <Col className="img-produit">
                <img
                  className=" img-responsive"
                  src={chalet3}
                  onClick={() => setImg(chalet3)}
                />
              </Col>
            </Row>
          </Col>

          <Col lg="6">
            <div key={3}>
              <h1 className="text-red font-bold mt-10">La ruche Chalet</h1>
              <h3 className="text-red mt-10">800 €</h3>
              <p className="font-bold">
                Ruche Dadant 12 cadres peuplée d’abeilles noires.
              </p>
              <div className="description">
                <p>
                  Vous avez des connaissances en apiculture et souhaitez créer
                  ou compléter un rucher avec une ruche d’abeilles endémiques ?{" "}
                  <br></br>
                  <br></br>
                  La ruche chalet Dadant 12 cadres que nous vous proposons est
                  habitées par nos abeilles noires. Vous venez les récupérer
                  entre mai et juin à Condrieu (69420) ou alentours (date
                  confirmée ultérieurement, dépendant de la météo)
                </p>
              </div>
            </div>

            <button
              className={[clicked ? "clicked cart-button" : "cart-button"]}
              onClick={() => submit()}
            >
              <span className="add-to-cart"> Ajouter au panier</span>
              <span className="added">Ajouté</span>
              <FontAwesomeIcon
                icon={faShoppingCart}
                className="fa-shopping-cart"
              />
              <FontAwesomeIcon icon={faBox} className="fa-box" />
            </button>
          </Col>
        </Row>

        <Row className="mt-10">
          <Col lg="12">
            <h4 className="text-red">Précisions importantes</h4>
            <p>
              Nos ruches sont <strong> fabriquées en France,</strong> en pin
              maritime 1er choix issu de{" "}
              <strong>nos forêts gérées durablement.</strong>
              <br></br>
              L’assemblage est à tenons et les parois font 25 mm d’épaisseur.
              <br></br> Les ruches chalets d’Apiago sont composées des éléments
              suivants :<br></br>
              <br></br>✦ Un toit chalet en bois tôlé
              <br></br>✦ Un couvre-cadres
              <br></br> ✦ Une hausse avec ses 11 cadres à jambage
              <br></br> ✦ Une grilles à reine
              <br></br> ✦ Un corps avec ses abeilles noires sur 12 cadres à
              jambage
              <br></br> ✦ Un plateau fond de ruche plein
              <br></br> ✦ Un auvent
              <br></br>✦ 4 pieds.
              <br></br>
              <br></br>
              Nos ruches chalets sont peintes ou lasurées.
              <br></br>
              <strong>
                Vous pourrez effectuer le choix de la couleur après le passage
                de la commande.
              </strong>
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chalet);
