import React, { useState } from "react";
import Footer from "../Footer/Footer";
//css

//bibliothèques
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import ReactFullpage from "@fullpage/react-fullpage";
import { Col, Row, Container } from "react-bootstrap";

//images
import chronologie from "../../assets/img/equipe/chronologie.png";
import clement from "../../assets/img/equipe/clement.jpg";
import laurence from "../../assets/img/equipe/laurence.jpg";
import pl from "../../assets/img/equipe/pl.jpeg";
import laurelyne from "../../assets/img/equipe/laurelyne.jpg";
import rodrigue from "../../assets/img/equipe/rodrigue.jpg";
import morgane from "../../assets/img/equipe/morgane.jpg";
//carousel
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper/core";
SwiperCore.use([Pagination]);

// Utilisation fullPage
const Equipe = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [active, setActive] = useState(1);

  const slideTo = (index) => {
    swiperRef.slideTo(index - 1, 0);
    setActive(index);
  };

  const onLeave = (origin, destination, direction) => {
    console.log("Leaving section " + origin.index);
  };
  const afterLoad = (origin, destination, direction) => {
    console.log("After load: " + destination.index);
  };

  return (
    <div className="fondEq">
      <ReactFullpage
        responsiveWidth={1000}
        navigation={true}
        onLeave={onLeave}
        afterLoad={afterLoad}
        render={({ state, fullpageApi }) => {
          return (
            <div id="fullpage-wrapper">
              {/* Section 1*/}
              <div className="section">
                <Container>
                  <Row>
                    <Col lg={{ span: 6, offset: 3 }} md="12">
                      <h1 className="text-light-blue font-bold font-26px text-uppercase">
                        Qui sommes-nous ?
                      </h1>
                      <br></br>
                      <div>
                        <p className="font-14px">
                          Six <strong>passionnés de la biodiversité</strong> et
                          des nouvelles technologies qui veulent mettre leurs
                          idées au service de la{" "}
                          <strong>préservation des abeilles</strong>.
                        </p>
                        <p className="font-14px">
                          Nous sommes convaincus que la{" "}
                          <strong>
                            préservation de la biodiversité est l’affaire de
                            tout un chacun
                          </strong>
                          . En tant qu’apiculteurs de biodiversité, nous
                          mobilisons nos connaissances et notre savoir-faire
                          afin de permettre aux{" "}
                          <strong>
                            entreprises, sites industriels, collectivités et
                            grand-public
                          </strong>{" "}
                          de réinstaller et préserver les pollinisateurs dans
                          notre environnement.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* Section 2*/}
              <div
                className="section"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              >
                <Container>
                  <Row className="text-center mt-10">
                    <Col md="12" className="sm-plr-10 font-14px">
                      <h1 className="font-italic text-uppercase font-26px   text-light-blue">
                        <div className="font-bold text-uppercase">
                          Bienvenue
                        </div>
                        <span className="font-italic">Chez apiago</span>
                      </h1>
                    </Col>
                  </Row>
                  <Row className="mt-10 plt-10">
                    <Col lg="4" md="12">
                      <h4 className="text-center text-light-blue font-20px font-bold">
                        Clément minière
                      </h4>
                      <div className="photo-equipe mx-auto text-center">
                        <img src={clement} />
                      </div>

                      <p className="text-center text-light-blue font-26px font-bold text-uppercase">
                        Chef des opérations
                      </p>
                      <p className="text-justify font-14px">
                        Il a travaillé plus de 10 ans en tant qu’ingénieur sur
                        sites industriels et en bureau d’études. <br></br>
                        Touche-à-tout et bricoleur averti, il suit de près
                        l’avancée des nouvelles technologies. <br></br>
                        Apiculteur amateur depuis quelques années et passionné
                        depuis l’enfance par l’électronique, <br></br>il est
                        l’initiateur du projet et en assure la direction.
                      </p>
                    </Col>
                    <Col lg="4" md="12" className="sm-mt-5">
                      <h4 className="text-center text-light-blue font-20px font-bold">
                        Laurence Royer
                      </h4>
                      <div className="photo-equipe mx-auto text-center">
                        <img src={laurence} />
                      </div>

                      <p className="text-center text-light-blue font-26px font-bold text-uppercase ">
                        Protectrice des idées
                      </p>
                      <p className="text-justify  font-14px">
                        Elle est diplômée de l’École Centrale Paris.
                        <br></br> Elle a été responsable de la maintenance des
                        matériels liés aux automatismes et à l’électricité au
                        sein d’un site industriel pendant 6 ans.<br></br> Depuis
                        2016 elle est ingénieure brevets pour un grand cabinet
                        lyonnais. Passionnée depuis l’enfance par l’apiculture,
                        <br></br> Apicultrice amatrice, elle est à l’origine du
                        projet.
                      </p>
                    </Col>
                    <Col lg="4" md="12" className="sm-mt-5">
                      <h4 className="text-center text-light-blue font-20px font-bold">
                        Pierre-Louis Royer
                      </h4>
                      <div className="photo-equipe mx-auto text-center">
                        <img src={pl} />
                      </div>

                      <p className="text-center text-light-blue font-26px font-bold text-uppercase">
                        Maître du fer à souder
                      </p>
                      <p className="text-justify  font-14px">
                        Il est ingénieur en électronique et télécommunications.{" "}
                        <br></br>Il a travaillé pendant plus de 10 ans en tant
                        que concepteur de cartes électroniques pour l'embarqué
                        ainsi que chef de projets pour un grand groupe.
                        <br></br> Ébéniste a ses heures perdues, il apporte ses
                        compétences au niveau technique et réalisation.{" "}
                        <br></br>Il assure la direction technique.
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-10 mb-10">
                    <Col lg="4" md="12" className="sm-mt-5">
                      <h4 className="text-center text-light-blue font-20px font-bold ">
                        Laurelyne Damier
                      </h4>
                      <div className="photo-equipe mx-auto text-center">
                        <img src={laurelyne} />
                      </div>

                      <p className="text-center text-light-blue font-26px font-bold text-uppercase">
                        Experte du clavier
                      </p>
                      <p className="text-justify  font-14px">
                        Elle est informaticienne de formation. <br></br>
                        Passionnée par le graphisme et réalisatrice de plusieurs
                        sites web, elle rejoint Apiago pour réaliser le
                        webdesign, travailler à l’identité visuelle de la marque
                        et développer les applications informatiques. <br></br>
                        Elle a immédiatement adhéré au projet et en est la geek.
                      </p>
                    </Col>
                    <Col lg="4" md="12" className="sm-mt-5">
                      <h4 className="text-center text-light-blue font-20px font-bold">
                        Rodrigue Richalland
                      </h4>
                      <div className="photo-equipe mx-auto text-center">
                        <img src={rodrigue} />
                      </div>

                      <p className="text-center text-light-blue font-26px font-bold text-uppercase">
                        As du tournevis
                      </p>
                      <p className="text-justify  font-14px">
                        Il est concepteur en mécanique. <br></br>Il est chargé
                        de la conception mécanique de la ruche Apiago ainsi que
                        des outils spécifiques nécessaires à sa fabrication.
                      </p>
                    </Col>
                    <Col lg="4" md="12" className="sm-mt-5">
                      <h4 className="text-center text-light-blue font-20px font-bold">
                        Morgane Le Carre
                      </h4>
                      <div className="photo-equipe mx-auto text-center">
                        <img src={morgane} />
                      </div>

                      <p className="text-center text-light-blue font-26px font-bold text-uppercase">
                        Gardienne des emojis
                      </p>
                      <p className="text-justify  font-14px">
                        Elle est diplômée d'un bachelor en design graphique, et
                        est aujourd'hui en mastère Direction Artistique.{" "}
                        <br></br>Chargée de la stratégie de communication et des
                        réseaux sociaux d'Apiago, elle nourrit l'aspect créatif
                        du projet au côté de Laurelyne.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* Section 3*/}
              <div className="section">
                <Container>
                  <Row>
                    <Col
                      lg={{ span: 7, offset: 4 }}
                      md="12"
                      className="sm-plr-10 sm-mb-10 font-14px"
                    >
                      <h1 className="font-italic text-uppercase font-26px   text-light-blue">
                        <div className="font-bold text-uppercase">
                          la naissance
                        </div>
                        <span className="font-italic">d'apiago</span>
                      </h1>
                      <br></br>
                      <div className="naissanceText">
                        <p>
                          En 2016, Laurence et Clément décident d’installer deux
                          ruches chez eux. Ils vivent au centre-ville de
                          Condrieu et ont un petit jardin de quelques dizaines
                          de mètres carrés. Ils placent les ruches sur leur
                          terrasse, séparée du parc de la maison des
                          associations par une haie vive. La sortie des ruches
                          est orientée vers le jardin, ce qui permet de vivre en
                          harmonie avec les abeilles. Laurence, apicultrice
                          amatrice depuis l’enfance, retrouve le plaisir de
                          s’occuper de ruches et Clément découvre le monde des
                          abeilles.
                        </p>
                        <p>
                          Leur objectif n’est pas d’obtenir du miel pour leur
                          consommation mais simplement le plaisir de voir
                          évoluer ces pollinisatrices dans les fleurs du jardin
                          et préserver des abeilles non sélectionnées pour leur
                          rendement mellifère. On parle alors de ruche de
                          biodiversité (Bertrand, 2015) Les framboisiers
                          n’avaient jamais autant donné que depuis 2016 !
                        </p>
                        <p>
                          <i className="text-light-blue">
                            Pour pallier l’actuelle mortalité élevée des
                            abeilles, Laurence et Clément se forment grâce à la
                            littérature spécialisée. Au contact d’associations
                            d’apiculteurs, ils s’aperçoivent que certains
                            apiculteurs amateurs ayant perdu trop de ruches ont
                            fini par baisser les bras et ne possèdent plus
                            d’essaim.
                          </i>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* Section 4*/}
              <div
                className="section"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              >
                <Container>
                  <Row className="text-center mt-10">
                    <Col md="12" className="sm-plr-10">
                      <h1 className="font-italic text-uppercase font-26px text-light-blue">
                        <div className="font-bold text-uppercase">
                          La chronologie
                        </div>
                        <span className="font-italic">d'apiago</span>
                      </h1>
                      <br></br>
                    </Col>

                    <Col md="12" className="text-center">
                      <img
                        className="img-chrono mb-10"
                        src={chronologie}
                        alt="Chronologie Apiago"
                      />
                    </Col>
                  </Row>
                </Container>
              </div>

              <div className="section section fp-auto-height">
                <Footer />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default Equipe;
{
  /* <Col
                      lg={{ span: 3, offset: 3 }}
                      md="12"
                      className="nomsCarousel align-self-center "
                    >
                      <h4
                        className={active == 1 ? "nameActive" : "textCarousel"}
                        onClick={() => slideTo(1)}
                      >
                        Clément Minière
                      </h4>
                      <h4
                        className={active == 2 ? "nameActive" : "textCarousel"}
                        onClick={() => slideTo(2)}
                      >
                        Laurence Royer
                      </h4>
                      <h4
                        className={active == 3 ? "nameActive" : "textCarousel"}
                        onClick={() => slideTo(3)}
                      >
                        Pierre-Louis Royer
                      </h4>
                      <h4
                        className={active == 4 ? "nameActive" : "textCarousel"}
                        onClick={() => slideTo(4)}
                      >
                        Laurelyne Damier
                      </h4>
                      <h4
                        className={active == 5 ? "nameActive" : "textCarousel"}
                        onClick={() => slideTo(5)}
                      >
                        Rodrigue Richalland
                      </h4>
                      <h4
                        className={active == 6 ? "nameActive" : "textCarousel"}
                        onClick={() => slideTo(6)}
                      >
                        Morgane Le Carre
                      </h4>
                    </Col>

                    <Col md="1" xs="12" className="align-self-center">
                      <img className="imgLigne" src={ligne} />
                    </Col> */
}

{
  /*  <Row>
                    <Col md="12">
                     <div class="carousel-equipe">
                         <Swiper
                          onSwiper={setSwiperRef}
                          direction={"vertical"}
                          className={
                            "align-self-center, swiper-container-equipe"
                          }
                          slidesPerView={1}
                          name="test"
                        >
                          <SwiperSlide className="slideEquipe">
                            <Row>
                              <Col md="4" className=" align-self-center">
                                <div className="photoEquipe ">
                                  <img src={clement} />
                                </div>
                              </Col>

                              <Col
                                md="8"
                                className="descriptionEquipe  align-self-center"
                              >
                                <p className="titreEquipe">
                                  Chef des opérations
                                </p>
                                <p>
                                  Il a travaillé plus de 10 ans en tant
                                  qu’ingénieur sur sites industriels et en
                                  bureau d’études. <br></br>Touche-à-tout et
                                  bricoleur averti, il suit de près l’avancée
                                  des nouvelles technologies. <br></br>
                                  Apiculteur amateur depuis quelques années et
                                  passionné depuis l’enfance par l’électronique,{" "}
                                  <br></br>il est l’initiateur du projet et en
                                  assure la direction.
                                </p>
                              </Col>
                            </Row>
                          </SwiperSlide>
                          <SwiperSlide className="slideEquipe">
                            <Row>
                              <Col md="4" className=" align-self-center">
                                <div className="photoEquipe ">
                                  <img src={laurence} />
                                </div>
                              </Col>

                              <Col
                                md="8"
                                className="descriptionEquipe  align-self-center"
                              >
                                <p className="titreEquipe">
                                  Protectrice des idées
                                </p>
                                <p>
                                  Elle est diplômée de l’École Centrale Paris.
                                  <br></br> Elle a été responsable de la
                                  maintenance des matériels liés aux
                                  automatismes et à l’électricité au sein d’un
                                  site industriel pendant 6 ans.<br></br> Depuis
                                  2016 elle est ingénieure brevets pour un grand
                                  cabinet lyonnais. Passionnée depuis l’enfance
                                  par l’apiculture,
                                  <br></br>elle est à l’origine du projet des
                                  ruches pour amateur et en assure le contrôle
                                  technique.
                                </p>
                              </Col>
                            </Row>
                          </SwiperSlide>
                          <SwiperSlide className="slideEquipe">
                            <Row>
                              <Col md="4" className=" align-self-center">
                                <div className="photoEquipe ">
                                  <img src={pl} />
                                </div>
                              </Col>

                              <Col
                                md="8"
                                className="descriptionEquipe  align-self-center"
                              >
                                <p className="titreEquipe">
                                  Maître du fer à souder
                                </p>
                                <p>
                                  Il est ingénieur en électronique et
                                  télécommunications. <br></br>Il a travaillé
                                  pendant plus de 10 ans en tant que concepteur
                                  de cartes électroniques pour l'embarqué ainsi
                                  que chef de projets pour un grand groupe.
                                  <br></br> Ébéniste a ses heures perdues, il
                                  apporte ses compétences au niveau technique et
                                  réalisation. <br></br>Il assure la direction
                                  technique.
                                </p>
                              </Col>
                            </Row>
                          </SwiperSlide>
                          <SwiperSlide className="slideEquipe">
                            <Row>
                              <Col md="4" className=" align-self-center">
                                <div className="photoEquipe ">
                                  <img src={laurelyne} />
                                </div>
                              </Col>

                              <Col
                                md="8"
                                className="descriptionEquipe  align-self-center"
                              >
                                <p className="titreEquipe">
                                  Experte du clavier
                                </p>
                                <p>
                                  Elle est informaticienne de formation.{" "}
                                  <br></br>Passionnée par le graphisme et
                                  réalisatrice de plusieurs sites web, elle
                                  rejoint Apiago pour réaliser le webdesign,
                                  travailler à l’identité visuelle de la marque
                                  et développer les applications informatiques.{" "}
                                  <br></br>Elle a immédiatement adhéré au projet
                                  et en est la geek.
                                </p>
                              </Col>
                            </Row>
                          </SwiperSlide>
                          <SwiperSlide className="slideEquipe">
                            <Row>
                              <Col md="4" className=" align-self-center">
                                <div className="photoEquipe ">
                                  <img src={rodrigue} />
                                </div>
                              </Col>

                              <Col
                                md="8"
                                className="descriptionEquipe  align-self-center"
                              >
                                <p className="titreEquipe">As du tournevis</p>
                                <p>
                                  Il est concepteur en mécanique. <br></br>Il
                                  est chargé de la conception mécanique de la
                                  ruche Apiago ainsi que des outils spécifiques
                                  nécessaires à sa fabrication.<br></br>Il à
                                  rejoint Apiago suite au recommandation de
                                  Laurelyne.
                                </p>
                              </Col>
                            </Row>
                          </SwiperSlide>
                          <SwiperSlide className="slideEquipe">
                            <Row>
                              <Col md="4" className=" align-self-center">
                                <div className="photoEquipe ">
                                  <img src={morgane} />
                                </div>
                              </Col>

                              <Col
                                md="8"
                                className="descriptionEquipe  align-self-center"
                              >
                                <p className="titreEquipe">
                                  Gardienne des emojis
                                </p>
                                <p>
                                  Elle est diplômée d'un bachelor en design
                                  graphique, et est aujourd'hui en mastère
                                  Direction Artistique. <br></br>Chargée de la
                                  stratégie de communication et des réseaux
                                  sociaux d'Apiago, elle nourrit l'aspect
                                  créatif du projet au côté de Laurelyne.
                                </p>
                              </Col>
                            </Row>
                          </SwiperSlide>
                        </Swiper>
                      </div>
                    </Col>
                  </Row>*/
}
