import React, { useState } from "react";
import Footer from "../Footer/Footer";
import axios from "axios";

//bibliothèques
import { Col, Row, Container } from "react-bootstrap";
import { ReCaptcha } from "react-recaptcha-google";

var Recaptcha = require("react-recaptcha");

// Utilisation fullPage
const Contact = () => {
  const [mailSent, setmailSent] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msgButton, setMsgButton] = useState("Envoyer");

  const handleFormSubmit = (e) => {
    console.log(formData);
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.PUBLIC_URL}` + "/api/contact/mail.php",
      headers: { "content-type": "application/json" },
      data: formData,
    })
      .then((result) => {
        if (result.data.sent) {
          setmailSent(result.data.sent);
          setError(false);
          setLoading(false);
          setMsgButton("Envoyé !");
        } else {
          setError(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  const handleChange = (e, field) => {
    let value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  return (
    <>
      <div class="fondShop"></div>
      <Container style={{ marginBottom: 70 }}>
        <Row className="d-block">
          <Col lg="1" className="mt-10"></Col>
          <Col
            lg="8"
            md="12"
            className="card card-contact sm-mlr-5 sm-mb-10 mx-auto my-auto"
          >
            <h2 className="text-red text-uppercase font-bold text-center">
              Contact
            </h2>
            <p className="font-16px">
              Laissez-nous un message, nous vous répondrons au plus vite.
            </p>
            <form action="">
              <input
                type="text"
                name="fullname"
                id="fullname"
                required={true}
                placeholder="Votre nom complet *"
                className="form-contact"
                onChange={(e) => handleChange(e, "fullname")}
              />

              <input
                onChange={(e) => handleChange(e, "email")}
                type="email"
                name="email"
                className="form-contact"
                id="email"
                required={true}
                placeholder="Votre email *"
              />

              <input
                onChange={(e) => handleChange(e, "subject")}
                type="text"
                name="subject"
                className="form-contact"
                id="subject"
                required={true}
                placeholder="Sujet *"
              />

              <textarea
                onChange={(e) => handleChange(e, "message")}
                name="message"
                id="message"
                className="form-contact-message"
                required={true}
                rows="7"
                placeholder="Votre message"
              />
              {/* <ReCaptcha
                ref={(el) => {
                  this.captchaDemo = el;
                }}
                size="normal"
                render="explicit"
                sitekey="6LeyJc8ZAAAAAC2i8rbz1P3PUi0zRY5QdY6ArC_8"
                onloadCallback={this.onLoadRecaptcha}
                verifyCallback={this.verifyCallback}
              /> */}
              <div className="text-center">
                <button
                  type="submit"
                  name="submit"
                  onClick={(e) => handleFormSubmit(e)}
                  className="btn btn-dark rounded-pill btn-block"
                >
                  {loading ? "..." : msgButton}
                </button>
              </div>
              <div>
                <br></br>
                {mailSent && (
                  <div className="font-20px text-blue">
                    Merci pour votre message! Nous vous répondrons dès que
                    possible
                  </div>
                )}
                {error && (
                  <div className="font-20px text-red">
                    Merci de remplir tout le formulaire.
                  </div>
                )}
              </div>
            </form>
          </Col>
          {/* <Col lg="5" md="12" className="card card-contact sm-mlr-5 sm-mb-10">
            <h2 className="text-red text-uppercase text-center font-bold">
              FAQ
            </h2>
            <p className="font-16px">
              Nous nous efforçons de répondre ici à vos principales
              interrogations mais n'hésitez pas à nous contacter!
            </p>
          </Col> */}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
