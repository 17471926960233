//base
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

//bibliothèques
import ReactFullpage from "@fullpage/react-fullpage";
import "fullpage.js/vendors/scrolloverflow";
import { Col, Row, Container } from "react-bootstrap";
//images
import apiago from "../../assets/img/magasin/apiago/apiago1.jpg";
import essaim from "../../assets/img/magasin/essaim/essaim1.jpg";
import chalet from "../../assets/img/magasin/chalet/chalet1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faAngleDoubleDown,
} from "@fortawesome/free-solid-svg-icons";

// Utilisation fullPage
class Shop extends React.Component {
  onLeave(origin, destination, direction) {
    console.log("Leaving section " + origin.index);
  }
  afterLoad(origin, destination, direction) {
    console.log("After load: " + destination.index);
  }
  render() {
    return (
      <div class="fondShop">
        <ReactFullpage
          responsiveWidth={1000}
          navigation={true}
          onLeave={this.onLeave.bind(this)}
          afterLoad={this.afterLoad.bind(this)}
          render={({ state, fullpageApi }) => {
            return (
              <div id="fullpage-wrapper">
                {/* Section 1*/}

                <div className="section">
                  <Container className="plr-5">
                    <Row>
                      <div>
                        <Col lg={{ span: 7, offset: 2 }} md="12">
                          <h1 className="font-italic text-uppercase font-20px text-red">
                            Apiago,<br></br>
                            <span className="font-26px font-bold font-normal text-uppercase">
                              Le magasin en ligne
                            </span>
                          </h1>
                          <p>
                            Apiago se consacre uniquement à l’apiculture de
                            biodiversité, celle qui contribue à préserver le
                            patrimoine génétique des abeilles mellifères
                            endémiques (l’abeille noire pour la France par
                            exemple) et privilégie leur bien-être.
                          </p>
                          <p>
                            Vous trouverez ici, seulement du matériel et des
                            essaims en accord avec notre démarche. Exit donc les
                            variétés d’abeilles sélectionnées pour leur
                            productivité et les ruches adaptées à la
                            transhumance.
                          </p>
                          <p>
                            Des idées de produits que vous aimeriez trouver ici
                            ?
                          </p>
                          <Link
                            to="/contact"
                            className="text-red-link font-bold"
                          >
                            Faites-le nous savoir{" "}
                            <FontAwesomeIcon
                              className="iconAccueil align-self-right"
                              icon={faChevronRight}
                            />
                          </Link>{" "}
                          <br></br>
                          <br></br>
                          <FontAwesomeIcon
                            className="text-red"
                            size="2x"
                            icon={faAngleDoubleDown}
                          />
                        </Col>
                      </div>
                    </Row>
                  </Container>
                </div>

                {/* Section 2*/}
                <div className="section plr-5 ">
                  <Container>
                    <Row>
                      <Col lg="4" className="align-self-center mt-10">
                        <Link to="/essaim-abeilles-noires" className="lien">
                          <div className="cardShop">
                            <h2 className="font-20px font-bold">
                              Essaim d'abeilles noires
                            </h2>
                            <img className="cardImg" src={essaim} />
                            <p className="text-center font-16px">
                              Vendu sur six cadres Dadant
                            </p>
                            <p className="text-center text-red font-26px">
                              189 €
                            </p>
                          </div>
                        </Link>
                      </Col>
                      <Col lg="4" className="align-self-center mt-10">
                        <Link to="/la-ruche-apiago" className="lien">
                          <div className="cardShop">
                            <h1 className="font-20px font-bold">
                              La ruche Apiago
                            </h1>
                            <img className="cardImg" src={apiago} />
                            <p className="text-center">
                              L’unique ruche Apiago, vendue avec son essaim
                              d’abeilles noires
                            </p>
                            <p className="text-center text-red font-26px">
                              2650 €
                            </p>
                          </div>
                        </Link>
                      </Col>
                      <Col lg="4" className="align-self-center mt-10">
                        <Link to="/la-ruche-chalet" className="lien">
                          <div className="cardShop">
                            <h3 className="font-20px font-bold">
                              La ruche Chalet
                            </h3>
                            <img className="cardImg" src={chalet} />
                            <p className="text-center font-16px">
                              Véritable ruche Dadant 12 cadres, avec son essaim
                              d’abeilles noires
                            </p>
                            <p className="text-center text-red font-26px">
                              800 €
                            </p>
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="section  mt-10">
                  <Footer />
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

export default Shop;
