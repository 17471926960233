//base
import React, { Component } from "react";
import { Col, Row, Container } from "react-bootstrap";
//css
import "bootstrap/dist/css/bootstrap.css";
import Footer from "../Footer/Footer";

class Cgv extends Component {
  render() {
    return (
      <>
        <Container>
          <Row className="d-block">
            <Col>
              <p className="text-red font-26px">
                Conditions générales de vente en vigueur à partir du 1er mai
                2021
              </p>
              <p>
                <br></br>
                <br></br>
                Les présentes conditions générales de vente régissent les
                modalités et les conditions de vente à distance des produits de
                la société Apiago (ci-après, le « Vendeur »), société par
                actions simplifiée, dont le siège social se situe au 75, route
                Nationale, 69420 Condrieu, France, et immatriculée au registre
                du commerce et des sociétés de Lyon sous le numéro 879 445 955.
                Apiago construit, maintient et opère un site Internet de
                commerce électronique (ci-après, le « Site ») : www.apiago.fr
                <br></br>
                <br></br>
                <strong className="text-red font-20px">
                  1. OBJET ET CHAMP D’APPLICATION{" "}
                </strong>
                <br></br>
                <br></br>
                Les produits (ci-après, le(s) « Produit(s) ») proposés à la
                vente sur le Site, sont des produits spécialisés en apiculture
                de biodiversité et des essaims d’abeilles. Le fait de passer
                commande sur le Site pour un ou plusieurs Produits implique
                l'adhésion automatique, irrévocable, entière et sans réserve du
                client (ci-après le « Client ») aux présentes conditions
                générales de vente. Elles régissent donc les relations
                contractuelles entre le Client et le Vendeur. Aucune condition
                particulière ne peut, sauf acceptation formelle et écrite
                d’Apiago, prévaloir sur ces conditions générales de vente.
                <br></br>
                <br></br>
                <strong className="text-red font-20px">
                  2. MODIFICATION DES PRÉSENTES CGV{" "}
                </strong>
                <br></br> Le Vendeur se réserve le droit, à tout moment, de
                modifier les présentes CGV. Le cas échéant, il sera appliqué, à
                chaque commande, les conditions générales de vente en vigueur au
                moment de la passation.
                <br></br>
                <br></br>
                <strong className="text-red font-20px">
                  3. MODALITÉS DE COMMANDE EN LIGNE
                </strong>
                <br></br>
                <br></br>
                <strong>3.1 Disponibilité des Produits</strong>
                <br></br>
                Les Produits proposés sur le Site, aux Clients, par le Vendeur,
                sont en principe disponibles, les mises à jour étant effectuées
                régulièrement. Cependant, il est précisé qu’un Produit peut
                apparaître comme « disponible » pendant la passation de la
                commande mais peut toutefois s’avérer indisponible au moment du
                paiement de la commande ou une fois la commande validée. Dans ce
                cas le Vendeur ne pourra en être tenu responsable. Si le Produit
                a été validé par le Client mais qu’il n’est plus disponible, le
                Vendeur enverra un courriel pouvant proposer un report de la
                date de livraison en attendant la disponibilité du Produit
                commandé. Le Client sera libre d’accepter ou non, la proposition
                du Vendeur, par retour de courriel. En cas de refus par le
                Client de la proposition qui lui est faite par le Vendeur, le
                Client sera intégralement remboursé du montant payé pour
                l’article indisponible. En outre, il est précisé que la
                responsabilité du Vendeur ne sera pas recherchée en cas de
                différences mineures entre les photos de présentation des
                articles et les textes affichés sur les fiches produits sur le
                Site, et les Produits livrés.
                <br></br>
                <br></br>
                <strong>
                  3.2 Cas particuliers des Produits contenant des abeilles
                </strong>
                <br></br>La disponibilité des essaims d’abeilles et les Produits
                contenant des essaims d’abeilles sont très dépendants des
                conditions météorologiques. Le Vendeur s’efforce de mettre à
                jour leur disponibilité en donnant sur la page Produit une
                fenêtre de dates pour leur disponibilité. En cas de décalage de
                ces dates, le Vendeur s’engage à en informer immédiatement par
                courriel le Client. Dans le cas d’un décalage supérieur à 30
                jours, le Client pourra exiger l’annulation de la commande et le
                remboursement intégral des sommes versées pour le Produit.
                <br></br>
                <br></br>
                <strong>3.3 Validation de la commande </strong>
                <br></br>Depuis la page « magasin » du Site, le Client passe sa
                commande en ajoutant les Produits à son « panier ». Aucun compte
                client n’est nécessaire pour le passage des commandes. Le Client
                peut à tout moment et ce jusqu’au paiement du prix, modifier,
                supprimer ou ajouter un Produit à sa commande ou tout simplement
                annuler cette dernière. Il peut également vérifier, à tout
                moment, jusqu’au paiement de la commande, les caractéristiques
                des Produits. Le Client doit ensuite sélectionner, le cas
                échéant, le mode de livraison choisi parmi les options proposées
                par le Vendeur et renseigner les informations demandées. Le
                Client doit cliquer sur « valider et payer ma commande » afin de
                pouvoir finaliser cette dernière. Il sélectionne ensuite le mode
                de paiement qu’il choisit parmi ceux proposés par le Vendeur.
                <br></br>
                <br></br>
                <strong>
                  3.4 Confirmation et récapitulatif de la commande par le
                  Vendeur
                </strong>{" "}
                <br></br>
                Les systèmes d'enregistrement automatique sont considérés comme
                valant preuve, de la nature, du contenu et de la date de la
                commande par le Client. Il est précisé que la commande ne pourra
                être considérée comme définitive qu’après réception par le
                Client d’un courrier électronique de confirmation de commande
                par le Vendeur. Ce courriel de confirmation récapitule notamment
                : les caractéristiques essentielles du ou des Produit(s) ainsi
                que le prix par produit (HT et TTC) et ses composantes
                éventuelles, les frais et l’adresse de livraison. L’envoi du
                courriel de confirmation de la commande se fait dans les 72h qui
                suivent la validation de cette dernière.
                <br></br>
                <br></br>
                <strong>3.5 Facturation </strong>
                <br></br> Après validation de la commande, une facture émise par
                Apiago et constatant le détail de la transaction, est transmise
                au Client par courriel. Le client peut obtenir un duplicata de
                sa facture, par courriel ou voie postale sur simple demande. 3.6
                Annulation de la commande par le Vendeur Le Vendeur se réserve
                le droit d'annuler toute commande d'un Client avec lequel
                existerait un litige relatif au paiement d'une commande
                antérieure. Les informations énoncées par le Client, lors de la
                prise de commande, engagent celui‐ci : en cas d'erreur dans le
                libellé des coordonnées du destinataire de la commande, le
                Vendeur ne saurait être tenu responsable de l'impossibilité dans
                laquelle il pourrait être, de livrer le Produit. Par ailleurs,
                aucune commande ne pourra être honorée tant que la totalité du
                prix n’aura pas été effectivement réglée par le Client.
                <br></br>
                <br></br>
                <strong className="text-red font-20px">
                  4. RETRAITS ET LIVRAISONS{" "}
                </strong>
                <br></br>
                Certains Produits (par ex. les essaims d’abeilles) ne sont pas
                disponibles à la livraison et seul le retrait par le Client est
                proposé. Le mode de mise à disposition du produit, par retrait
                ou par livraison, est stipulé sur la page du Produit. En cas de
                retrait, le lieu et le créneau de dates de mise à disposition
                sont mentionnés sur la page du Produit. Ils seront précisés à
                nouveau par courriel envoyé au Client par le Vendeur après
                validation de la commander. Les livraisons s’effectuent en
                France métropolitaine et à l’étranger par transporteur.
                <br></br> <br></br>{" "}
                <strong>4.1 Réception par le Client </strong>
                <br></br>Le Client est tenu de vérifier le contenu de la
                livraison lors de la réception de celle-ci et devra donc
                signaler sur le support présenté par le livreur (papier ou
                électronique) toute réserve par exemple pour un Produit
                manquant, non conforme ou endommagé. Il devra contacter le
                Service Clients dans les plus brefs délais afin de signaler le
                problème.
                <br></br>
                <br></br>{" "}
                <strong>4.2 Résolution du contrat par le Client</strong>
                <br></br>En cas de manquement du Vendeur à son obligation de
                livraison du bien à la date ou à l'expiration du délai prévu, à
                défaut, au plus tard trente (30) jours après la conclusion du
                contrat, le Client peut résoudre le contrat selon les modalités
                décrites dans les articles L 138-2 et suivants du Code de la
                consommation. Le Vendeur procèdera alors au remboursement du
                Produit dans les conditions de l’article L 138-3 du Code de la
                Consommation. Sans préjudice des droits qui lui sont reconnus
                par la loi, toute réclamation d'erreur de livraison et/ou de
                non‐conformité des Produits devra être formulée auprès du
                vendeur dans les 72h suivant la livraison.
                <br></br>
                <br></br>
                <strong className="text-red font-20px">
                  5. DROIT DE RÉTRACTATION{" "}
                </strong>
                <br></br> <br></br>
                <strong>5.1 Exercice du droit de rétraction </strong>
                <br></br>Conformément à l’article L 221-18 du Code de commerce,
                vous avez quatorze (14) jours à compter de la réception des
                Produits pour exercer votre droit de rétractation du contrat
                conclu à distance et ce, sans avoir à motiver votre décision,
                sous réserve que les Produits ne soient pas exclus du champ
                d’application (7.2 – article L 221-28 du Code de la
                consommation). En cas d'exercice du droit de rétractation, le
                Client doit en informer le Vendeur dans le délai de quatorze
                (14) jours, soit en lui adressant par courrier (papier ou
                électronique) le formulaire type Formulaire de rétractation,
                soit en indiquant clairement, par tout écrit, sa volonté de se
                rétracter, sans aucune ambiguïté et en incluant également toutes
                les informations nécessaires au traitement de la demande :
                coordonnées détaillées du Client, numéro de commande.
                <br></br> La demande de rétractation sera envoyée par courriel à
                l'adresse
                <p className="text-red">service.clients@apiago.fr</p> ou par
                courrier à l’adresse suivante :
                <p className="text-red">
                  APIAGO SAS 75 rue Nationale 69420 Condrieu France
                </p>
                <br></br>
                <br></br>
                <strong>5.2 Exclusion du droit de rétractation </strong>
                <br></br>Conformément à l’article L 221-28 du Code de la
                consommation, le Client ne peut pas exercer son droit de
                rétractation notamment dans les cas suivants : • Produits qui ne
                peuvent pas être retournés pour des raisons d'hygiène ou de
                protection de la santé, s’ils ont été descellés ou bien, s’ils
                ont, après avoir été livrés, été mélangés de manière
                indissociable avec d'autres articles • Livraison
                d'enregistrements audio ou vidéos ou de logiciels informatiques
                descellés après la livraison • Livraison de produits qui ont été
                confectionnés selon les spécifications du Client ou nettement
                personnalisés • Produits susceptibles de se détériorer ou de se
                périmer rapidement
                <br></br>
                <br></br>{" "}
                <strong> 5.3 Conditions de retour des produits </strong>
                <br></br>Le Client devra restituer le Produit au Vendeur sans
                retard excessif, et au plus tard, dans les quatorze (14) jours
                suivant la communication de sa décision de se rétracter. Il
                convient alors de renvoyer le Produit dans son emballage
                d'origine, en parfait état et non utilisé, accompagné le cas
                échéant de tous les accessoires, notices d'emploi et
                documentation à l'adresse suivante : APIAGO SAS 75 rue Nationale
                69420 Condrieu France Un Produit endommagé ou utilisé ne pourra
                en aucun cas faire l’objet d’un remboursement. Le Client accepte
                d’ores et déjà qu’en cas d’exercice de son délai de
                rétractation, la totalité des coûts de retour seront à sa charge
                (sauf si le Produit, par sa nature, ne peut pas être renvoyé
                normalement par voie postale, conformément à l’article L 221-23,
                al. 3 du Code de commerce).
                <br></br>
                <br></br>
                <strong> 5.4 Remboursement incombant au Vendeur </strong>
                <br></br>Le Vendeur est tenu au remboursement de la totalité des
                sommes versées par le Client, en ce compris, les frais de
                livraison standards, correspondant au mode de livraison le moins
                onéreux proposé par le Vendeur. Le remboursement est dû dans un
                délai maximum quatorze (14) jours suivant la date à laquelle le
                Vendeur a été informé de la décision du Client de se rétracter.
                Cependant, conformément à l’article L 221-24, al 1. du Code de
                la consommation, le Vendeur peut différer le remboursement
                jusqu’à récupération du Produit ou jusqu’à ce que le Client ait
                fourni une preuve de l‘expédition de ce Produit. Le Client sera
                remboursé par le moyen de paiement initialement utilisé lors de
                la passation de commande, sauf accord contraire des parties.
                <br></br>
                <br></br>
                <strong className="text-red">6. PRIX </strong>
                <br></br>Les prix sont exprimés en euros TTC. Le prix indiqué
                sur les fiches Produit ne comprend pas les frais de livraison.
                Aucun frais supplémentaire n’est appliqué aux Produits faisant
                l’objet d’un retrait. Une caution peut être, pour certains
                Produits, exigées (par ex. pour les essaims mis à disposition en
                ruchette consignée). Le montant de cette caution est alors, le
                cas échéant, précisé sur la page Produit. Le règlement de la
                caution par le Client est effectué avant le retrait du Produit
                concerné. Le remboursement de la caution est effectué par le
                Vendeur dès le retour par le Client de la partie consignée du
                Produit. Le Vendeur se réserve le droit de modifier les prix à
                tout moment. Toutefois, pour les commandes déjà passées, les
                prix définis sont ceux en vigueur le jour de la passation de
                commande.
                <br></br>
                <br></br>
                <strong className="text-red"> 7. PAIEMENT</strong>
                <br></br> Le prix des Produits est payable au comptant le jour
                de la commande effective. Le paiement peut s'effectuer par
                chèque, virement bancaire ou carte bancaire portant le sigle
                CB/Visa/Mastercard dans un environnement sécurisé SSL. La
                commande validée par le Client ne sera considérée comme
                définitive que lorsque les centres de paiement bancaire
                concernés auront donné leur accord. En cas de refus desdits
                centres, la commande sera automatiquement annulée et le Client
                prévenu par courriel.
                <br></br>
                <br></br>
                <strong className="text-red"> 8. GARANTIES LÉGALES </strong>
                <br></br>
                Apiago accorde la garantie légale de conformité de deux ans
                suivant l'achat (Article L. 211-1 à L. 211-4 du Code de la
                consommation) la garantie légale des vices cachés (Article 1641
                à 1649 du Code civil) ainsi que la garantie offerte de deux ans
                sur le territoire français, tel que plus amplement détaillé pour
                chacun des Produits sur les pages du Site. Apiago n'accordera ou
                ne sera garant d'aucune garantie additionnelle de quelque nature
                que ce soit et sur quelque Produit offert sur le Site. Apiago
                indiquera sur la page des Produits, le cas échéant, les
                modalités et conditions du service après-vente en fonction de
                chacun des Produits vendus. En cas de besoin, le client pourra
                toujours contacter Apiago pour connaître la démarche à suivre.
                <br></br>
                Les garanties ne couvrent pas :<br></br>• le remplacement des
                consommables ;<br></br>• l'utilisation anormale ou non conforme
                des produits ;<br></br>• les pannes liées aux accessoires ;
                <br></br>• les défauts et leurs conséquences liés à toute cause
                extérieure ;<br></br>• les essaims d’abeilles.
                <br></br>
                <br></br>
                <strong className="text-red">
                  9. Disponibilité des pièces détachées
                </strong>
                <br></br>Conformément à l’article L 111-3 du Code de la
                Consommation, Apiago indique que la durée de disponibilité des
                pièces détachées des produits commercialisés sur le site est de
                10 ans pour tous les produits.
                <br></br>
                Pour toute question, remarque ou réclamation, le Service Clients
                est à votre disposition :<br></br>• Par téléphone au 06 23 11 46
                21
                <br></br>• Par courrier électronique au :
                service.clients@apiago.fr
                <br></br>• Par courrier à l’adresse suivante : Apiago SAS 75 rue
                Nationale 69420 Condrieu France 10.
                <br></br>
                <br></br>
                <strong className="text-red font-26px">
                  PROPRIÉTÉ INTELLECTUELLE
                </strong>
                <br></br> Le contenu du Site est la propriété exclusive du
                Vendeur et ce dans sa totalité. Ceci inclut notamment, tous les
                textes mais aussi tout logo, image, signe distinctif ou
                graphisme quelconque pouvant se trouver sur le Site. Le Site est
                protégé au titre du droit de la propriété intellectuelle et
                toute reproduction, copie, modification, téléchargement ou
                atteinte quelconque, qu’elle soit intégrale ou partielle, sans
                l'autorisation expresse du Vendeur, est strictement interdite et
                expose son auteur à des poursuites.
                <br></br>
                <br></br>
                <strong className="text-red">
                  11. RESPONSABILITÉ DU VENDEUR
                </strong>
                <br></br>
                <br></br>
                <strong>11.1 Site internet</strong>
                <br></br>Il est rappelé que le Vendeur est simplement tenu à une
                obligation de moyen concernant la fourniture du service du Site
                internet. Si le Vendeur s’engage à faire ses meilleurs efforts
                en vue de maintenir une continuité dans les services proposés
                par le Site, il ne pourra cependant en aucun cas être tenu
                responsable pour des pannes ou problèmes d’ordre technique
                pouvant provoquer une interruption partielle ou totale du
                service sur le Site. Par ailleurs, le Vendeur se réserve le
                droit d’interrompre la continuité du service, notamment dans le
                cadre d’une opération de maintenance ou pour toute modification
                ou amélioration qu’il jugerait appropriée. Le Vendeur, ne
                pourra, en aucun cas, être tenu responsable d’un quelconque
                dommage pouvant découler de ce fait. Il est rappelé que le
                Client est entièrement responsable de la protection de son
                matériel et de ses équipements informatiques et ne pourra en
                aucun cas engager la responsabilité du Vendeur si ledit
                équipement ou matériel venait à être endommagé dans le cadre de
                l’utilisation du Site.
                <br></br>
                <br></br>
                <strong>11.2 Force majeure</strong>
                <br></br> Le Vendeur peut dans certains cas, s’exonérer de sa
                responsabilité en démontrant que l’inexécution ou la mauvaise
                exécution de ses obligations en vertu du présent contrat est
                imputable, soit au Client, soit provient d’un cas de force
                majeur, c’est-à-dire d’un fait échappant au contrôle du Vendeur
                ou tout autre fait rentrant dans la définition de la force
                majeure au regard de la législation en vigueur.
                <br></br>
                <br></br>
                <strong className="text-red">12. DROIT APPLICABLE</strong>
                <br></br>Les présentes conditions générales de vente sont
                régies, tant pour leur interprétation que pour leur exécution,
                par la loi française.
                <br></br>
                <br></br>
                <strong className="text-red">
                  13. Loi informatique et liberté
                </strong>
                <br></br> Les informations qui sont demandées au Client sont
                nécessaires au traitement de sa commande et pourront être
                communiquées aux partenaires contractuels d’Apiago intervenant
                dans le cadre de l'exécution de cette commande. Le Client peut
                écrire à la société Apiago pour exercer ses droits d'accès et de
                rectification à l'égard des informations le concernant et
                figurant dans les fichiers de la société Apiago dans les
                conditions prévues par la loi du 6 janvier 1976. Pour toute
                demande relative à l’exercice de ses droits concernant ses
                données personnelles, le Client peut exercer sa demande
                (indiquant son identité précise) à l’adresse suivante :
                service.clients@apiago.fr Toute demande doit être signée et
                accompagnée d’une pièce permettant de justifier de son identité.
              </p>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}

export default Cgv;
