import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import cartReducer from "./redux/reducers/cartReducer";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react";
import { initState } from "./redux/reducers/cartReducer";

const persistConfig = {
  key: "react-root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, cartReducer);

export const store = createStore(cartReducer);
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}

    <App />
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById("react-root")
);
