import React from "react";
import Footer from "../Footer/Footer";

// bibliothèque
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

class Merci extends React.Component {
  render() {
    return (
      <>
        <div className="fondM">
          <Container>
            <Row>
              <Col lg={{ span: 4, offset: 4 }} className="text-center">
                <p className="font-30px text-light-blue font-bold mt-10 text-uppercase">
                  merci pour votre commande !
                </p>
                <a>
                  <Link to="/"> retour au site </Link>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </>
    );
  }
}

export default Merci;
