import React, { Component } from "react";
import { Col, Row, Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  removeItem,
  addQuantity,
  subtractQuantity,
} from "../../redux/actions/cartActions";
//Stripe
import { loadStripe } from "@stripe/stripe-js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const stripePromise = loadStripe(
  "pk_live_51IIxixKTRoGtcVqMj84Z0aZrabELxHmlbRL4stXwSdoIQHIN6jeeBUpyqDyGZ41fuzsNMPAmTwsdgenKYoGFCECl00qn94XiIm"
);

class Panier extends Component {
  state = {
    itemList: [],
    btnDisable: true,
  };

  check = () => {
    this.setState({
      btnDisable: !this.state.btnDisable,
    });
  };
  //to remove the item completely
  handleRemove = (id) => {
    this.props.removeItem(id);
  };
  //to add the quantity
  handleAddQuantity = (id) => {
    this.props.addQuantity(id);
  };
  //to substruct from the quantity
  handleSubtractQuantity = (id) => {
    this.props.subtractQuantity(id);
  };

  getItemList = () => {
    this.props.items.map((item) => {
      const newItem = {
        price: item.key,
        quantity: item.quantity,
      };

      this.state.itemList.push(newItem);
    });
  };

  submit = async () => {
    this.getItemList();
    console.log("test", this.state.itemList);

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: this.state.itemList,
      mode: "payment",
      successUrl: "https://apiago.fr/Merci",
      cancelUrl: "https://apiago.fr/Oups",
    });
  };

  render() {
    let addedItems = this.props.items.length ? (
      this.props.items.map((item) => {
        return (
          <tr key={item.id}>
            <th className="border-0">
              <Row>
                <Col lg="3" md="12">
                  <img
                    width="200"
                    className="img-fluid rounded shadow-sm"
                    src={item.img}
                    alt=""
                  />
                </Col>
                <Col
                  lg="9"
                  md="12"
                  className="d-inline-block align-self-center justify-content-md-center"
                >
                  <h5 className="mb-0">
                    <a className="text-red d-inline-block align-self-center justify-content-md-center">
                      {item.title}
                    </a>
                  </h5>
                  <span className="text-muted font-weight-normal font-italic d-block">
                    {item.desc}
                  </span>
                </Col>
              </Row>
            </th>

            <td className="border-0 align-middle">
              <h3 className="text-red">{item.price * item.quantity} €</h3>
            </td>

            <td className="border-0 align-middle">
              {item.quantity > 1 ? (
                <button
                  className="btn btn-dark rounded-pill btn-qt btn-rounded"
                  onClick={() => {
                    this.handleSubtractQuantity(item.id);
                  }}
                >
                  -
                </button>
              ) : null}

              <strong>{item.quantity}</strong>

              <button
                className="btn btn-dark rounded-pill btn-qt btn-rounded"
                onClick={() => {
                  this.handleAddQuantity(item.id);
                }}
              >
                +
              </button>
            </td>

            <td className="align-middle border-0">
              <a
                onClick={() => {
                  this.handleRemove(item.id);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} id="shop" />
              </a>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td>
          <p>Votre panier est vide.</p>
        </td>
      </tr>
    );

    return (
      <>
        <div class="fondPanier"></div>
        <Container className="panier">
          <div className="text-center">
            <h1 className="font-60px text-red-link">Mon panier</h1>
          </div>

          <div>
            <Row>
              <Col md="12" className="shadow-md mb-5">
                <Link to="/magasin" className="text-red-link">
                  <FontAwesomeIcon icon={faChevronLeft} id="shop" /> Retour a la
                  page produit
                </Link>
              </Col>
              {/*  Shopping cart table  */}
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr className="text-red-link">
                      <th scope="col" className="border-0 bg-light">
                        <Col md="4" className="text-uppercase">
                          Produit
                        </Col>
                      </th>
                      <th scope="col" className="border-0 bg-light">
                        <Col md="2" className="text-uppercase">
                          Prix
                        </Col>
                      </th>
                      <th scope="col" className="border-0 bg-light">
                        <Col md="1" className="text-uppercase">
                          Quantité
                        </Col>
                      </th>
                      <th scope="col" className="border-0 bg-light"></th>
                    </tr>
                  </thead>

                  <tbody>{addedItems}</tbody>
                </table>
              </div>
            </Row>
          </div>

          <div className="row py-5 p-4 bg-white rounded shadow-sm">
            <Col lg="6" md="12">
              <div className="bg-light rounded-pill px-4 py-3 text-uppercase font-weight-bold">
                Panier
              </div>
              <div className="p-4">
                <p className="font-italic mb-4"></p>
                <ul className="list-unstyled mb-4">
                  <li className="d-flex justify-content-between py-3 border-bottom">
                    <strong className="text-muted">TVA</strong>
                    <strong>0 €</strong>
                  </li>
                  <li className="d-flex justify-content-between py-3 border-bottom">
                    <strong className="text-muted">Total</strong>
                    <h5 className="font-weight-bold" id="cart-total">
                      {this.props.total} €
                    </h5>
                  </li>
                </ul>
                <input
                  type="checkbox"
                  required
                  name="terms"
                  id="check"
                  className="checkbox"
                  onClick={() => this.check()}
                />
                &nbsp;J'accepte les
                <Link to="/cgv" className="text-red-link" target="_blank">
                  &nbsp;Conditions d'achats
                </Link>
                <button
                  className={
                    this.state.btnDisable
                      ? "disabled btn btn-dark rounded-pill btn-block"
                      : "btn btn-dark rounded-pill btn-block"
                  }
                  onClick={() => this.submit()}
                >
                  Payement par carte
                </button>
              </div>
            </Col>

            <Col md="12" lg="6">
              <div className="bg-light rounded-pill px-4 py-3 text-uppercase font-weight-bold">
                Autre methode de payement
              </div>
              <div className="p-4">
                <p className="font-italic mb-4">
                  Le paiement par chèque de façon automatisé arrive bientôt en
                  ligne, si vous souhaitez passer par cette méthode de paiement,
                  envoyez-nous un message sur la page de{" "}
                  <Link to="/contact" className="text-red-link font-bold">
                    contact
                  </Link>{" "}
                  avec vos informations et les articles que vous souhaitez
                  acquérir.
                </p>

                <a
                  href="#"
                  className="btn btn-dark rounded-pill btn-block disabled"
                  data-redirect-to-checkout
                >
                  Payement par chèque
                </a>
                <a
                  href="#"
                  className="btn btn-dark rounded-pill btn-block disabled"
                  data-redirect-to-checkout
                >
                  Payement par virement banquaire
                </a>
              </div>
            </Col>
          </div>
        </Container>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.addedItems,
    total: state.total,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeItem: (id) => {
      dispatch(removeItem(id));
    },
    addQuantity: (id) => {
      dispatch(addQuantity(id));
    },
    subtractQuantity: (id) => {
      dispatch(subtractQuantity(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Panier);
