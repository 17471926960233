import React from "react";
//img
import ruche from "../../assets/img/icon/ruche.png";
import suivis from "../../assets/img/icon/suivis.png";
import bilan from "../../assets/img/icon/bilan.png";
import bio from "../../assets/img/icon/bio.png";
import com from "../../assets/img/icon/com.png";
import miel from "../../assets/img/icon/miel.png";
import visible from "../../assets/img/icon/visible.png";
import img from "../../assets/img/autre/entreprise.png";

//bibliothèques
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import ReactFullpage from "@fullpage/react-fullpage";
import { Col, Row, Container } from "react-bootstrap";
import Footer from "../Footer/Footer";

//Carousel
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
SwiperCore.use([Pagination, Navigation, Autoplay]);

// Utilisation fullPage
class Collectivite extends React.Component {
  onLeave(origin, destination, direction) {
    console.log("Leaving section " + origin.index);
  }
  afterLoad(origin, destination, direction) {
    console.log("After load: " + destination.index);
  }
  render() {
    return (
      <ReactFullpage
        responsiveWidth={1000}
        navigation={true}
        onLeave={this.onLeave.bind(this)}
        afterLoad={this.afterLoad.bind(this)}
        render={({ state, fullpageApi }) => {
          return (
            <div id="fullpage-wrapper">
              {/* Section 1*/}
              <div className="section">
                <Container fluid>
                  <Row>
                    <Col lg="6" md="12" className="sm-mb-10">
                      <div className="loc-img1"></div>
                    </Col>
                    <Col
                      lg="4"
                      md="12"
                      className="align-self-center mx-auto my-auto sm-plr-10 font-14px"
                    >
                      <h1 className="font-italic text-uppercase font-20px text-red">
                        Une ruche<br></br>
                        <span className="font-26px font-bold font-normal text-uppercase">
                          symbole de votre engagement
                        </span>
                      </h1>
                      <br></br>
                      <p>
                        Soyons honnêtes :{" "}
                        <strong>
                          se contenter d’installer des ruches de production de
                          miel pour sauver les abeilles ou préserver la
                          biodiversité n’a pas de sens.
                        </strong>
                      </p>
                      <p>
                        Cela peut même être contre-productif : une ruche compte
                        plusieurs dizaines de milliers d’individus à nourrir.
                        Les butineuses doivent ainsi prélever dans
                        l’environnement de la ruche d’importantes quantités de
                        nectar et de pollen, ce qui diminue d’autant les
                        ressources disponibles pour les autres pollinisateurs.
                        De plus, si les abeilles mellifères de la ruche en
                        question sont des abeilles hybridées par l’homme dans le
                        but d’augmenter les rendements en termes d’exploitation
                        apicole, cela devient même contre-productif en termes de
                        préservation de la diversité génétique de nos abeilles
                        mellifères.
                      </p>

                      <p>
                        Installer une <strong>ruche de biodiversité </strong>
                        tout en{" "}
                        <strong>
                          recréant des espaces naturels, sources de nourriture
                          pour l’ensemble des espèces indigènes{" "}
                        </strong>
                        (dont l’abeille mellifère fait partie, pour peu qu’il
                        s’agisse de la bonne espèce) démontre une{" "}
                        <strong>
                          véritable connaissance des enjeux actuels.
                        </strong>
                      </p>
                      <p>
                        Notre ruche de biodiversité est alors{" "}
                        <strong>
                          l’étendard d’une démarche engagée en faveur de la
                          préservation des pollinisateurs
                        </strong>
                        .<br></br>
                        <br></br>
                        <strong>
                          Faites-le choix d’Apiago,{" "}
                          <a href="/contact" className="text-red-link">
                            contactez-nous
                          </a>
                        </strong>
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* Section 2*/}
              <div className="section">
                <Container fluid>
                  <Row>
                    <Col
                      lg={{ span: 6, order: 1 }}
                      md={{ span: 12, order: 2 }}
                      className="align-self-center font-14px"
                    >
                      <Swiper
                        navigation={true}
                        pagination={true}
                        className="mySwiper"
                        autoplay={{
                          delay: 3000,
                          disableOnInteraction: true,
                        }}
                      >
                        <SwiperSlide className="text-center mx-auto my-auto ">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={visible} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              UNE RUCHE VISIBLE
                            </h3>
                            <br></br>
                            <p className="sm-plr-10">
                              Nous installons une{" "}
                              <strong>ruche de biodiversité Apiago® </strong> à
                              un emplacement visible du public,
                              <strong>
                                {" "}
                                étendard de votre démarche engagée en faveur de
                                la biodiversité.
                              </strong>
                              <br></br>
                              Un panneau d’informations est mis en place à
                              proximité.
                              <br></br>
                              <br></br>
                              <strong>Envie de personnalisation ? </strong>
                              <br></br> Nous proposons la personnalisation de la
                              ruche à votre identité visuelle. Tout, ou presque,
                              est possible !
                            </p>
                          </Col>
                        </SwiperSlide>
                        <SwiperSlide className="text-center mx-auto my-auto">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={ruche} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              parrainage De la biodiversité
                            </h3>
                            <br></br>
                            <p className=" sm-plr-10">
                              La possibilité{" "}
                              <strong>pour tout un chacun</strong>, via notre
                              site Internet, de{" "}
                              <strong>parrainer votre démarche </strong> engagée
                              pour la biodiversité et recevoir, en échange, des
                              pots de miel à vos couleurs, produits par des
                              apiculteurs indépendants.
                              <br></br>
                              <br></br>Notre espace web leur permet de suivre
                              l'actualité de la colonie d’abeilles et être
                              informés des démarches mises en œuvre en faveur de
                              la biodiversité grâce à leur parrainage.
                            </p>
                          </Col>
                        </SwiperSlide>
                        <SwiperSlide className="text-center mx-auto my-auto">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={suivis} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              un suivi quotidien
                            </h3>
                            <br></br>
                            <p className="sm-plr-10">
                              Nous effectuons un{" "}
                              <strong>suivi quotidien et à distance </strong>
                              de la santé de l’essaim. La connectivité (GSM) de
                              la ruche permet de ne pas déranger les abeilles.
                              <br></br>
                              <br></br>
                              Nous assurons{" "}
                              <strong>
                                des abeilles en bonne santé toute l’année{" "}
                              </strong>
                              et intervenons sur place dès que cela est
                              nécessaire.{" "}
                              <strong>
                                Un numéro d’astreinte vous permet de nous
                                joindre 24h/24.
                              </strong>
                            </p>
                          </Col>
                        </SwiperSlide>
                        <SwiperSlide className="text-center mx-auto my-auto">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={com} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              de la communication
                            </h3>
                            <br></br>
                            <p className="sm-plr-10">
                              Nous vous fournissons du{" "}
                              <strong>contenu régulier </strong> permettant de{" "}
                              <strong>
                                communiquer largement sur votre démarche engagée
                                en faveur de la biodiversité.
                              </strong>
                              <br></br>
                              <br></br>
                              Chaque année au printemps, nous organisons sur le
                              lieu d’installation de votre ruche une{" "}
                              <strong>
                                journée d’animations et découvertes sur le thème
                                de la biodiversité.
                              </strong>
                              <br></br>
                              Les parrains de votre démarche sont incités à
                              <strong> venir à votre rencontre </strong> pour
                              récupérer leurs pots de miel. Sur vos espaces,
                              nous{" "}
                              <strong>
                                agissons directement afin de recréer des espaces
                                plus naturels,
                              </strong>{" "}
                              propices au retour et à la conservation de la
                              biodiversité.
                            </p>
                          </Col>
                        </SwiperSlide>
                        <SwiperSlide className="text-center mx-auto my-auto">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={bilan} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              un bilan annuel
                            </h3>
                            <br></br>
                            <p className="sm-plr-10">
                              Avant installation, nous effectuons un état des
                              lieux. Un document, rédigé par nos soins, listera
                              les actions possibles et sera mis à jour de façon
                              annuelle afin de vous permettre de{" "}
                              <strong>
                                {" "}
                                suivre l’évolution de votre démarche
                              </strong>{" "}
                              en faveur de la biodiversité et d’en mesurer les
                              résultats.
                            </p>
                          </Col>
                        </SwiperSlide>
                        <SwiperSlide className="text-center mx-auto my-auto">
                          <Col md="8" className="text-center mx-auto my-auto">
                            <img src={bio} className="mb-10" />
                            <h3 className="text-uppercase font-bold font-20px text-light-blue">
                              votre engagement
                            </h3>
                            <br></br>
                            <p className="sm-plr-10">
                              Vous agissez en faveur de la biodiversité autour
                              d’un <strong>projet mobilisateur.</strong> Les
                              fonds récoltés grâce aux parrainages viennent
                              s’ajouter à vos efforts entrepris dans le cadre de
                              cette démarche globale. Ils seront utilisés pour
                              l’achat et la mise en œuvre de semences, plants et
                              autres dispositifs écologiques.
                              <br></br>
                              <br></br>
                            </p>
                            <p className="sm-plr-10 text-red">
                              Se contenter d’installer des ruches de production
                              de miel pour sauver les abeilles n’a pas de sens.
                              <br></br>
                              <br></br>
                              <strong>
                                Installer une ruche de biodiversité et recréer
                                des espaces naturels démontre une véritable
                                connaissance des enjeux actuels. Faites-le
                                savoir grâce à Apiago !
                              </strong>
                            </p>
                            <br></br>
                          </Col>
                        </SwiperSlide>
                      </Swiper>
                    </Col>

                    <Col lg={{ span: 6, order: 2 }} md={{ span: 12, order: 1 }}>
                      <div className="loc-img2"></div>
                    </Col>
                  </Row>
                </Container>
              </div>

              <div className="section fp-auto-height">
                <Footer />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

export default Collectivite;
